import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, IconButton, Paper, Box, Slide, Drawer } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

var offsetCenter = 100;

const useStyles = makeStyles((theme) => ({
  hideExpandBarMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarXs: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  widthLateral: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "0px",
    },
  },
  widthLateralCollapsed: {
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "0px",
    },
  },
  widthCenter: {
    [theme.breakpoints.down("sm")]: {
      //   width: "calc(100% - 50px)",
      width: "100%",
    },
    // width: "calc(100% - 50px)",
    width: "calc(100% - 100px)",
  },

  widthCenterOnlyOneView: {
    [theme.breakpoints.down("sm")]: {
      //   width: "calc(100% - 50px)",
      width: "100%",
    },
    // width: "calc(100% - 50px)",
    width: "calc(100% - 50px)",
  },

  widthCenterOneViewExpanded: {
    [theme.breakpoints.down("sm")]: {
      //   width: "calc(100% - 50px)",
      width: "100%",
    },
    // width: "calc(100% - 50px)",
    width: "calc(100% - 250px)",
  },

  widthCenterOneView: {
    [theme.breakpoints.down("sm")]: {
      //   width: "calc(100% - 50px)",
      width: "100%",
    },
    // width: "calc(100% - 50px)",
    width: "calc(100% - 300px)",
  },
  widthCenterTwoView: {
    [theme.breakpoints.down("sm")]: {
      //   width: "calc(100% - 50px)",
      width: "100%",
    },
    // width: "calc(100% - 50px)",
    width: "calc(100% - 500px)",
  },

  drawerWidth: {
    width: 250,
  },
}));

const Viewport = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const props_viewEnable = props.viewEnable || {
    left: false,
    right: false,
  };

  const props_iconLeft = props.iconLeft;
  const props_collapsedLeft = props.collapsedLeft;
  const props_onCollapsedLeft = props.onCollapsedLeft;
  const props_onExpandLeft = props.onExpandLeft;

  const props_iconRight = props.iconRight;
  const props_collapsedRight = props.collapsedRight;
  const props_onCollapsedRight = props.onCollapsedRight;
  const props_onExpandRight = props.onExpandRight;
  const props_forceDrawerRight = props.forceDrawerRight;

  const props_contentLeft = props.contentLeft;
  const props_contentRight = props.contentRight;

  const [stateCollapsedLeft, setStateCollapsedLeft] = React.useState(true);
  const [stateCollapsedRight, setStateCollapsedRight] = React.useState(true);
  const [drawer, setDrawer] = React.useState({ left: false, right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const selectClassForCenterWidth = () => {
    //esiste configurata solo una vista sx o dx
    if (
      (props_viewEnable.left && !props_viewEnable.right) ||
      (!props_viewEnable.left && props_viewEnable.right)
    ) {
      if (stateCollapsedLeft) return classes.widthCenterOnlyOneView;
      return classes.widthCenterOneViewExpanded;
    }
    // sono configurate entrambe le viste
    return (!stateCollapsedLeft || !stateCollapsedRight) &&
      !(!stateCollapsedLeft && !stateCollapsedRight)
      ? classes.widthCenterOneView
      : !stateCollapsedLeft && !stateCollapsedRight
      ? classes.widthCenterTwoView
      : classes.widthCenter;
  };

  useEffect(() => {
    if (props_collapsedLeft == null || props_collapsedLeft == undefined) return;
    if (props_collapsedLeft != stateCollapsedLeft)
      setStateCollapsedLeft(props_collapsedLeft);
  }, [props_collapsedLeft]);

  useEffect(() => {
    if (props_collapsedRight == null || props_collapsedRight == undefined)
      return;
    if (props_collapsedRight != stateCollapsedRight)
      setStateCollapsedRight(props_collapsedRight);
  }, [props_collapsedRight]);

  return (
    <>
      {/* <Grid container justify={window.innerWidth > 900 ? "left" : "center"}> */}
      <Grid container justify={"center"}>
        {props_viewEnable.left && (
          <div
            id="view-left"
            className={
              !stateCollapsedLeft
                ? classes.widthLateral
                : classes.widthLateralCollapsed
            }
            style={{
              marginRight: theme.spo.mSm - 5,
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            {/* left view Md */}
            <Paper
              className={[
                classes.hideExpandBarMd,
                !stateCollapsedLeft
                  ? classes.widthLateral
                  : classes.widthLateralCollapsed,
              ].join(" ")}
              style={{
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                position: "absolute",
                left: 0,
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }}
            >
              <Box>
                <IconButton
                  color="primary"
                  aria-label="menu laterale"
                  onClick={() => {
                    setStateCollapsedLeft(!stateCollapsedLeft);
                  }}
                >
                  {props_iconLeft}
                  {/* md */}
                </IconButton>
              </Box>
              <Slide
                direction="right"
                in={!stateCollapsedLeft}
                mountOnEnter
                unmountOnExit
                // timeout={{ appear: 2000, enter: 500, exit: 0 }}
                timeout={{ enter: 500 }}
              >
                <div className={classes.hideExpandBarMd}>
                  {props_contentLeft}
                </div>
              </Slide>
            </Paper>
            {/* Left view Xs */}
            <Paper
              className={classes.hideExpandBarXs}
              style={{
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                position: "fixed",
                left: 0,
                opacity: 0.5,
              }}
            >
              <Box>
                <IconButton
                  color="primary"
                  aria-label="menu laterale"
                  onClick={toggleDrawer("left", true)}
                >
                  {props_iconLeft}
                </IconButton>
              </Box>

              <Drawer
                anchor={"left"}
                open={drawer["left"]}
                onClose={toggleDrawer("left", false)}
              >
                <div
                  className={classes.drawerWidth}
                  role="presentation"
                  // onClick={toggleDrawer("left", false)}
                  onKeyDown={toggleDrawer("left", false)}
                >
                  {props_contentLeft}
                </div>
              </Drawer>
            </Paper>
          </div>
        )}
        <Grid
          container
          id="view-center"
          className={selectClassForCenterWidth()}
          spacing={3}
          style={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          {props.children}
        </Grid>
        {props_viewEnable.right && (
          <div
            id="view-right"
            className={
              !stateCollapsedRight
                ? classes.widthLateral
                : classes.widthLateralCollapsed
            }
            style={{
              marginLeft: theme.spo.mSm - 5,
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            {/* Right view Md */}
            {!props_forceDrawerRight && (
              <Paper
                className={classes.hideExpandBarMd}
                style={{
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                  textAlign: "right",
                }}
              >
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="menu laterale"
                    onClick={() => {
                      setStateCollapsedRight(!stateCollapsedRight);
                    }}
                  >
                    {props_iconRight}
                  </IconButton>
                </Box>
                <Slide
                  direction="left"
                  in={!stateCollapsedRight}
                  mountOnEnter
                  unmountOnExit
                  // timeout={{ appear: 5000, enter: 100, exit: 100 }}
                >
                  <div className={classes.hideExpandBarMd}>
                    {props_contentRight}
                  </div>
                </Slide>
              </Paper>
            )}

            {props_forceDrawerRight && (
              <Paper
                className={classes.hideExpandBarMd}
                style={{
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  position: "fixed",
                  right: 0,
                }}
              >
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="menu laterale"
                    onClick={toggleDrawer("right", true)}
                  >
                    {props_iconRight}
                  </IconButton>
                </Box>

                <Drawer
                  anchor={"right"}
                  open={drawer["right"]}
                  onClose={toggleDrawer("right", false)}
                >
                  <div
                    className={classes.drawerWidth}
                    role="presentation"
                    onKeyDown={toggleDrawer("right", false)}
                  >
                    {props_contentRight}
                  </div>
                </Drawer>
              </Paper>
            )}
            {/* Right view Xs */}
            <Paper
              className={classes.hideExpandBarXs}
              style={{
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                position: "fixed",
                right: 0,
                opacity: 0.5,
              }}
            >
              <Box>
                <IconButton
                  color="primary"
                  aria-label="menu laterale"
                  onClick={toggleDrawer("right", true)}
                >
                  {props_iconRight}
                </IconButton>
              </Box>

              <Drawer
                anchor={"right"}
                open={drawer["right"]}
                onClose={toggleDrawer("right", false)}
              >
                <div
                  className={classes.drawerWidth}
                  role="presentation"
                  onKeyDown={toggleDrawer("right", false)}
                >
                  {props_contentRight}
                </div>
              </Drawer>
            </Paper>
          </div>
        )}
      </Grid>
    </>
  );
};

export default Viewport;
