import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { GET, POST, DELETE, PUT } from "request-helper";
import DialogConfirm from "../components/DialogConfirm";
import Footer from "../components/Footer";
import LayoutWithHead from "../components/LayoutWithHead";
import DialogLoader from "../components/DialogLoader";
import BodyWithLeftBar from "../components/Product/BodyWithLeftBar";
import Logo from "../components/Logo";
import { roundTwoDigit } from "../utility/utility";
import SummaryProductInCart from "../components/Product/SummaryProductInCart";

import { FormattedNumber } from "react-intl";
import { NotificationManager } from "react-notifications";
import Moment from "react-moment";

import {
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  Typography,
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";

import {
  faCartPlus, //creato 1
  faShoppingCart, //accettato 3
  faCartArrowDown, //ricevuto punto vendita 10
  faClipboardCheck, // confermato punto vendita 12
  faBox, //Pronto al ritiro 20
  faTruckLoading, //Ordine pronto per la spedizione 22
  faTruckMoving, //Spedito 25
  faPeopleCarry, //ricevuto ok 90
  faArrowLeft,
  faTrashAlt,
  faTimes,
  faExclamationTriangle,
  faMoneyCheckAlt,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import CardProduct from "../components/Product/CardProduct";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Orders = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [totalCoupon, setTotalCoupon] = React.useState(0);
  const [listOrder, setListOrder] = React.useState(null);
  const [listProductInCart, setListProductInCart] = React.useState(null);
  const [listStatus, setListStatus] = React.useState(null);
  const [showProductDialog, setShowProductDialog] = React.useState(false);
  const [idFilterStatus, setIdFilterStatus] = React.useState(null);
  const [dateStart, setDateStart] = React.useState(null);
  const [dateEnd, setDateEnd] = React.useState(null);

  const [AmountIncr, setAmountIncr] = React.useState(null);

  const getIconForStatus = (idStatus) => {
    switch (idStatus) {
      case 1:
        return <FontAwesomeIcon icon={faCartPlus} />;
      case 3:
        return <FontAwesomeIcon icon={faShoppingCart} />;
      case 10:
        return <FontAwesomeIcon icon={faCartArrowDown} />;
      case 12:
        return <FontAwesomeIcon icon={faClipboardCheck} />;
      case 20:
        return <FontAwesomeIcon icon={faBox} />;
      case 22:
        return <FontAwesomeIcon icon={faTruckLoading} />;
      case 25:
        return <FontAwesomeIcon icon={faTruckMoving} />;
      case 90:
        return <FontAwesomeIcon icon={faPeopleCarry} />;
      case 999:
        return <FontAwesomeIcon icon={faTimes} />;
      case 1000:
        return <FontAwesomeIcon icon={faMoneyCheckAlt} />;
      case 1010:
        return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }
  };

  const fatcheListStatusType = () => {
    let param = {};
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListStatusType",
      null,
      param,
      true
    ).then((data) => {
      if (!data && !data.filter) return;
      let types = data.filter((c) => c.id > 2);
      setListStatus(types);
    });
  };

  const fatcheListOrder = () => {
    let param = {
      filterStatus: idFilterStatus,
      start: dateStart,
      end: dateEnd,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListHistoryCart",
      null,
      param,
      true
    ).then((data) => {
      setListOrder(data);
    });
  };

  const fatcheListProducts = (idcart, totalCoupon) => {
    let param = { idcart };
    setTotalCoupon(totalCoupon);
    GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ListCartProductFromId",
      null,
      param,
      true
    ).then((data) => {
      setListProductInCart(data);
      setShowProductDialog(true);
      if (data) {
        setAmountIncr(
          roundTwoDigit(
            data.reduce((accumul, prod) => accumul + prod.adjustedAmount, 0)
          )
        );
      }
    });
  };

  const fatchCancelOrder = (idcart) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/CancelCart?idcart=" +
        idcart,
      null,
      null,
      true
    ).then((data) => {
      NotificationManager.success("Ordine annullato correttamente");
      fatcheListOrder();
      fatcheListStatusType();
    });
  };

  const handleClose = () => {
    setShowProductDialog(false);
  };

  useEffect(() => {
    fatcheListOrder();
    fatcheListStatusType();
    var interval = setInterval(() => {
      // setListOrder(listOrder);
      fatcheListOrder();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fatcheListOrder();
  }, [idFilterStatus, dateStart, dateEnd]);

  return (
    <div style={{ marginTop: theme.spo.mSm }}>
      <DialogLoader show={!listOrder}></DialogLoader>
      {listOrder && (
        <div>
          <LayoutWithHead
            rowLogoLeft={<Logo></Logo>}
            rowBar={
              <Grid item xs={6}>
                <Tooltip title="Torna agli acquisti">
                  <IconButton
                    id="backtoProduct"
                    style={{ fontSize: "45px", padding: 0 }}
                    color="primary"
                    onClick={() => {
                      props.history.push(
                        process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
          >
            <BodyWithLeftBar
              contentLeft={
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="shop-select-label">Stato</InputLabel>

                    <Select
                      labelId="shop-select-label"
                      id="shop-select"
                      value={idFilterStatus}
                      onChange={(e) => {
                        setIdFilterStatus(e.target.value);
                      }}
                    >
                      {listStatus &&
                        listStatus.map((c) => {
                          return (
                            <MenuItem value={c.id}>{c.description}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                      <DatePicker
                        style={{ width: "100%" }}
                        format="dd/MM/yyyy"
                        // margin="normal"
                        id="date-picker-da"
                        label="Da"
                        value={dateStart}
                        onChange={(date) => {
                          setDateStart(date);
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                      <DatePicker
                        style={{ width: "100%" }}
                        format="dd/MM/yyyy"
                        // margin="normal"
                        id="date-picker-a"
                        label="a"
                        value={dateEnd}
                        onChange={(date) => {
                          setDateEnd(date);
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </Box>
              }
              contentRight={
                <Grid container style={{ paddingLeft: theme.spo.pSm }}>
                  <Grid xs={12}>
                    {listOrder && listOrder.map && (
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Stato Ordine</TableCell>
                              <TableCell>Data/ora ordine</TableCell>
                              <TableCell>Numero ordine</TableCell>
                              <TableCell>Punto vendita</TableCell>
                              <TableCell>Modalità</TableCell>
                              <TableCell>Previsto il</TableCell>
                              <TableCell>Numero Prodotti</TableCell>
                              <TableCell>Totale</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listOrder.map((row) => (
                              <TableRow
                                hover
                                key={row.idProduct}
                                // onClick={() => {
                                //   fatcheListProducts(row.id);
                                // }}
                              >
                                <TableCell component="th" scope="row">
                                  <Tooltip title={row.status}>
                                    <IconButton
                                      aria-label="status"
                                      onClick={() => {
                                        fatcheListProducts(
                                          row.id,
                                          row.totalCoupon
                                        );
                                      }}
                                    >
                                      {getIconForStatus(row.idStatus)}
                                    </IconButton>
                                  </Tooltip>
                                  {row.dateCancellation &&
                                    new Date(row.dateCancellation) >
                                      new Date() && (
                                      <DialogConfirm
                                        title={"Elimina"}
                                        text={
                                          "Sei sicuro di voler cancellare l'ordine?"
                                        }
                                      >
                                        {(confirm) => (
                                          <Tooltip title="Annulla ordine">
                                            <IconButton
                                              onClick={confirm(() => {
                                                fatchCancelOrder(row.id);
                                              })}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrashAlt}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </DialogConfirm>
                                    )}
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  {row.dataDelivery && (
                                    <Moment format="DD-MM-YYYY">
                                      {row.dataDelivery}
                                    </Moment>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <div>{row.id}</div>
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <div>{row.shopDescription}</div>
                                  <div>{row.shopAddress}</div>
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <div>{row.typeDelivery}</div>
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <Moment format="DD-MM-YYYY">
                                    {row.data}
                                  </Moment>{" "}
                                  alle ore{" "}
                                  <Moment
                                    parse="YYYY-MM-DD HH:mm"
                                    format="HH:mm"
                                  >
                                    {row.timeStart}
                                  </Moment>
                                  /
                                  <Moment
                                    parse="YYYY-MM-DD HH:mm"
                                    format="HH:mm"
                                  >
                                    {row.timeEnd}
                                  </Moment>
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <div>{row.totalArticle}</div>
                                </TableCell>
                                <TableCell
                                  className={"hoverCursor"}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    fatcheListProducts(row.id, row.totalCoupon);
                                  }}
                                >
                                  <div>
                                    <FormattedNumber
                                      value={roundTwoDigit(
                                        row.totalAmount +
                                          row.totalAdjustedAmount -
                                          row.totalCoupon
                                      )}
                                      style="currency"
                                      currency="EUR"
                                      currencySign="accounting"
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              }
            ></BodyWithLeftBar>
          </LayoutWithHead>
          <Footer></Footer>
        </div>
      )}
      <Dialog
        fullScreen
        open={showProductDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              Prodotti Acquistati
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Chiudi
            </Button>
          </Toolbar>
        </AppBar>

        <SummaryProductInCart
          cartSummary={{ webSpoProductInCart: listProductInCart }}
          AmountIncr={AmountIncr}
          showPercIncr={false}
          couponTotal={totalCoupon}
        ></SummaryProductInCart>

        {/* <Grid container spacing={3}>
          {listProductInCart &&
            listProductInCart.map &&
            listProductInCart.map((c) => {
              //azzero le promozioni , non so se è ancora valida
              c.vwWebSpoProduct.promoPrice = 0;
              c.vwWebSpoProduct.prChiaroPeso = 0;
              return (
                <CardProduct
                  product={c.vwWebSpoProduct}
                  overridePrice={c.price}
                  readOnly={true}
                  hidePromo={true}
                  cart={{ webSpoProductInCart: listProductInCart }}
                ></CardProduct>
              );
            })}
        </Grid> */}
      </Dialog>
    </div>
  );
};
export default Orders;
