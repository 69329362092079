import { SET_USER_LOGGED, SET_CFG_USER_VIEWAPP } from "../actionTypes";

const initialState = {
  viewAppCfg: {}
};

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOGGED: {
      state = { ...state, ...action.payload.user };
      return state;
    }
    case SET_CFG_USER_VIEWAPP: {
      let v = action.posts;
      //v.cassieri = false;
      //v.coladdLabel = "";
      //v.fAnnoprec = true;
      //v.fContapersone = true;
      state = { ...state, ...{ viewAppCfg: v } };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default UserReducers;
