import { combineReducers } from "redux";

import LayoutReducers from "./Common/LayoutReducers";
import PageReducers from "./Common/PageReducers";
import UserReducers from "./UserReducers";
import AppCfgReducers from "./AppCfgReducers";
import AppReducers from "./AppReducers";
import CartReducers from "./CartReducers";

import FilterSalesReducer from "./FilterSalesReducer";
import SalesReducers from "./SalesReducer";

export default combineReducers({
  LayoutReducers,
  PageReducers,
  UserReducers,
  AppCfgReducers,
  AppReducers,
  SalesReducers,
  FilterSalesReducer,
  CartReducers,
});
