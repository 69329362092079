import { SET_INFO_CART, SET_TOTAL_CART } from "../actionTypes";

const initialState = null;

const CartReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_CART: {
      if (state == null) state = {};
      if (action.payload.infoCart == null) {
        state = {};
        return state;
      }
      state.infoCart = action.payload.infoCart;
      state = { ...state, ...action.payload.infoCart };
      return state;
    }

    case SET_TOTAL_CART: {
      state = { ...state, ...{ total: action.payload } };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default CartReducers;
