import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { roundTwoDigit } from "../../utility/utility";

import { Box, ButtonGroup, Button } from "@material-ui/core";

const ProductCounter = (props) => {
  const props_readOnly = props.readOnly;
  const props_product = props.product;
  const props_setCount = props.setCount;
  const props_count = props.count;
  const props_removeProduct = props.removeProduct;
  const props_updateQuantity = props.updateQuantity;

  const theme = useTheme();

  return (
    <div>
      <Box
        style={{
          paddingRight: theme.spo.mSm,
          fontSize: "12px",
          height: "14px",
        }}
      ></Box>
      <ButtonGroup size="small" aria-label="small outlined button group">
        {props_readOnly == false && (
          <Button
            onClick={() => {
              let c = roundTwoDigit(props_count - props_product.qtaBase);
              props_setCount(c);
              if (c <= 0) {
                props_removeProduct(props_product);
                return;
              }
              props_updateQuantity(props_product, c);
            }}
          >
            -
          </Button>
        )}

        <Button disabled>
          {roundTwoDigit(props_count)} {props_product.unmis}
        </Button>
        {props_readOnly == false && (
          <Button
            onClick={() => {
              let c = roundTwoDigit(props_count + props_product.qtaBase);
              props_setCount(c);
              props_updateQuantity(props_product, c);
            }}
          >
            +
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default ProductCounter;
