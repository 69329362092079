import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  IconButton,
  Slide,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

const ContentLeftBar = (props) => {
  const theme = useTheme();
  const props_idCategory = props.idCategory;
  const props_brand = props.brand;
  const props_setBrand = props.setBrand;
  const props_listBrand = props.listBrand;
  const props_listCategory = props.listCategory;
  const props_onChangeSelectCategory = props.onChangeSelectCategory;

  return (
    <Box style={{ padding: theme.spo.pSm }}>
      {props_listCategory ? (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="category-select-label">Categoria</InputLabel>

          <Select
            // className={}
            labelId="category-select-label"
            id="category-select"
            value={props_idCategory}
            onChange={props_onChangeSelectCategory}
          >
            {props_listCategory &&
              props_listCategory.map &&
              props_listCategory.map((c) => {
                return (
                  <MenuItem key={"list-cat-" + c.idCatWebS} value={c.idCatWeb}>
                    {c.desCatWeb}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
      ) : (
        "Nessun reparto selezionato"
      )}
      {props_listBrand && props_listBrand.map && (
        <Box
          key={"list-brand-box" + Math.floor(Math.random() * 999)}
          style={{
            paddingTop: theme.spo.mMd,
          }}
        >
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Marche</FormLabel>
            <FormGroup>
              <Box
                style={{
                  paddingTop: theme.spo.mMd,
                  height: "450px",
                  overflow: "auto",
                }}
              >
                {props_listBrand &&
                  props_listBrand.map &&
                  props_listBrand.map((c) => {
                    return (
                      <div key={"list-brand-check" + c.idBrand}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props_brand[c.idBrand]}
                              onChange={(event) => {
                                props_setBrand({
                                  ...props_brand,
                                  [event.target.name]: event.target.checked,
                                });
                              }}
                              name={c.idBrand}
                            />
                          }
                          label={c.brandDescription}
                        />
                      </div>
                    );
                  })}
              </Box>
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default ContentLeftBar;
