import React, {
  Component,
  useState,
  useEffect,
  useContext,
  lazy,
  Suspense,
} from "react";
import { Route, Redirect } from "react-router";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import Callback from "./Pages/Callback";
import Login from "./Pages/Login";
import WizardUser from "./Pages/WizardUser";
import WizardCart from "./Pages/WizardCart";
import GestioneAccount from "./Pages/GestioneAccount";
import Account from "./Pages/Account";
import SpoHome from "./Pages/SpoHome";
import FreeHome from "./Pages/FreeHome";
import Product from "./Pages/Product";
import ProductShop from "./Pages/ProductShop";
import CartSummary from "./Pages/CartSummary";
import Detail from "./Pages/Detail";
import Orders from "./Pages/Orders";
import BarCode from "./Pages/BarCode";
import PaymentMode from "./Pages/PaymentMode";
import PaymentOk from "./Pages/PaymentOk";
import PaymentKo from "./Pages/PaymentKo";
import RedirectManageUser from "./Pages/RedirectManageUser";

import AppTheme from "./theme/AppTheme";
import FactoryTheme from "./theme/FactoryTheme";

import { ListMenu } from "./menuConfig";

import { UserManager } from "oidc-client";
import {
  addDefaultUnAuthorized,
  addDefaultFail,
  getCounterRequest,
} from "request-helper";
import { addHeaders } from "request-helper";
import { loginConfig } from "./loginConfig";

import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import { IntlProvider } from "react-intl";

import store from "./redux/store";

import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

function App(props) {
  const [listRoutingComponent, setListRoutingComponent] = useState([]);

  addDefaultUnAuthorized(() => {
    var mgr = new UserManager(loginConfig);
    mgr.signinRedirect();
  });

  addDefaultFail((error) => {
    // alert("Si è verificato un errore");
    NotificationManager.error("Si è verificato un errore", "Attenzione");
  });

  const generateClassName = createGenerateClassName({
    productionPrefix: "app-",
    disableGlobal: true,
  });

  // setInterval(function() {
  //   props.fetchSalesDateAction();
  // }, 20000);

  useEffect(() => {
    addHeaders({
      "Content-Type": "application/json",
      AppCode: process.env.REACT_APP_CLIENT_ID,
      customer: process.env.REACT_APP_CUSTOMER,
    });
  }, []);

  useEffect(() => {
    var listVoiceMenu = ListMenu();

    listVoiceMenu.map((item, index) => {
      //use filter true deve andare nella pagina di filter
      if (item.useFilter === true) {
        item.component = lazy(() => {
          // return import("./components/Filter.js");
          return import("./Pages/Filter/" + item.entity + "/FilterEntity.js");
        });
      }
      //use filter false deve andare direttamente alla pagina filter
      if (item.useFilter === false) {
        item.component = lazy(() => {
          return null;
        });
      }
      //use filter è null or undefined utilizza la proprietà import
      if (item.useFilter === undefined || item.useFilter == null) {
        item.component = lazy(() => {
          return import("./Pages/Mask/" + item.import + ".js");
        });
      }
    });

    setListRoutingComponent(listVoiceMenu);
  }, []);

  // var cmpRender = <Cmp id={e.name}></Cmp>;
  function makeRoute(e, cmpRender) {
    //use filter true deve andare nella pagina di filter
    if (e.useFilter === true) {
      return (
        <Route
          exact
          path={process.env.REACT_APP_BASE_PATH_ROUTE + "/Filter/:processId"}
          component={cmpRender}
        />
      );
    }
    //use filter false deve andare direttamente alla pagina filter
    if (e.useFilter === false) {
      return null;
    }
    //use filter è null or undefined utilizza la proprietà import
    if (e.useFilter === undefined || e.useFilter === null) {
      return (
        <Route
          exact
          path={process.env.REACT_APP_BASE_PATH_ROUTE + e.route}
          component={cmpRender}
        />
      );
    }
  }

  const getLanguage = () => {
    var state = store.getState();
    return state.AppCfgReducers.language;
  };

  const theme = createMuiTheme(FactoryTheme().theme);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider locale={getLanguage()}>
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/"}
              component={SpoHome}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/callback"}
              component={Callback}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/login"}
              component={Login}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardUser"}
              component={WizardUser}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/home"}
              component={SpoHome}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/main"}
              component={FreeHome}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart"}
              component={WizardCart}
            />
            <Route
              exact
              path={
                process.env.REACT_APP_BASE_PATH_ROUTE +
                "/GestioneAccount/:backTo"
              }
              component={GestioneAccount}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/Account"}
              component={Account}
            />
            <Route
              exact
              path={
                process.env.REACT_APP_BASE_PATH_ROUTE +
                "/Account/:operation/:userid"
              }
              component={RedirectManageUser}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/Product"}
              component={Product}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/ProductShop"}
              component={ProductShop}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/Summary"}
              component={CartSummary}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/Orders"}
              component={Orders}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/PaymentMode"}
              component={PaymentMode}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/PaymentOk"}
              component={PaymentOk}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/PaymentKo"}
              component={PaymentKo}
            />
            <Route
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/detail/:idDetail"}
              component={Detail}
            />
            <Route
              path={process.env.REACT_APP_BASE_PATH_ROUTE + "/barCode/:barcode"}
              component={BarCode}
            />
            <NotificationContainer />
          </IntlProvider>
        </StylesProvider>
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default App;
