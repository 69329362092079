import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import LayoutWithHead from "../components/LayoutWithHead";
import Footer from "../components/Footer";
import Logo from "../components/Logo";

import {
  Paper,
  Link,
  Button,
  Tooltip,
  Grid,
  Box,
  IconButton,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

const Account = (props) => {
  const theme = useTheme();
  return (
    <div>
      <LayoutWithHead
        rowLogoLeft={<Logo></Logo>}
        rowBar={
          <Grid item xs={6}>
            <Tooltip title="Torna agli acquisti">
              <IconButton
                style={{ fontSize: "45px", padding: 0 }}
                color="primary"
                onClick={() => {
                  props.history.push(
                    process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                  );
                }}
              >
                <FontAwesomeIcon icon={faChevronCircleLeft} />
              </IconButton>
            </Tooltip>
          </Grid>
        }
      >
        <Grid container spacing={3} style={{ paddingBottom: theme.spo.pSm }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={3}>
                <Paper style={{ height: "150px", padding: theme.spo.pMd }}>
                  <Link
                    href="#"
                    onClick={() => {
                      props.history.push(
                        process.env.REACT_APP_BASE_PATH_ROUTE +
                          "/GestioneAccount/Account"
                      );
                    }}
                  >
                    Dati anagrafici
                  </Link>
                  <h6>
                    Permette di modificare i dati registrati in fase di
                    attivazione nome , cognome, indirizzi etc.
                  </h6>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Paper style={{ height: "150px", padding: theme.spo.pMd }}>
                  <Link
                    href="#"
                    onClick={() => {
                      window.location =
                        process.env.REACT_APP_AUTHORITY +
                        "/Identity/Account/Manage" +
                        "?returnUrl=" +
                        window.location.href;
                    }}
                  >
                    Dati Account
                  </Link>
                  <h6>
                    Permette di modificare i dati accesso all'applicazione
                  </h6>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Paper style={{ height: "150px", padding: theme.spo.pMd }}>
                  <Link href="#" onClick={() => {}}>
                    Contattaci
                  </Link>
                  <h6></h6>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </LayoutWithHead>
      <Footer></Footer>
    </div>
  );
};
export default Account;
