import {
  SET_USER_LOGGED,
  SET_APP_CFG,
  SET_SALES_MASTER,
  SET_FILTER_MASTER,
  SET_SALES_MASTER_RANGE,
  SET_TYPE_MASTER_PAGE,
  SET_LANGUAGE,
  SET_SPO_CFG,
  SET_SPO_CFG_SHOP,
  SAVE_STATE_APP,
  SET_INFO_CART,
  SET_TOTAL_CART,
} from "./actionTypes";
import { GET } from "request-helper";
import makeFetch from "./makeFetch";

export const SetAppConfigFromServer = (cfg) => ({
  type: SET_APP_CFG,
  payload: cfg,
});

export const SaveStateApp = (stateToSave) => ({
  type: SAVE_STATE_APP,
  payload: stateToSave,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const SetUserLogged = (user) => ({
  type: SET_USER_LOGGED,
  payload: { user },
});

export function fetchCfgSpo(filter) {
  return makeFetch(SET_SPO_CFG, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Config/GetConfigFrontEndApplication",
      null,
      null,
      true
    );
  });
}

export function fetchCfgShopSpo(shopId) {
  return makeFetch(SET_SPO_CFG_SHOP, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Config/GetConfigFrontendShop",
      null,
      {
        shopId,
      },
      true
    );
  });
}

export const SetInfoCart = (infoCart) => ({
  type: SET_INFO_CART,
  payload: { infoCart },
});

export const SetTotalCart = (total) => ({
  type: SET_TOTAL_CART,
  payload: total,
});

//codice altro progetto da eliminare
export const setTypeMasterPage = (name) => ({
  type: SET_TYPE_MASTER_PAGE,
  payload: name,
});

export const setFilterSalesAction = (filter) => ({
  type: SET_FILTER_MASTER,
  payload: { ...filter },
});

export function fetchSalesDateAction(filter) {
  let qs = "";
  if (filter && filter.dateFilter && filter.dateFilter.day.from) {
    qs += "?DateStart=" + filter.dateFilter.day.from.toISOString();
  }

  return makeFetch(SET_SALES_MASTER, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_VIEWAPP + "v1/Sales/GetSalesFromDate" + qs,
      null,
      null,
      false
    );
  });
}

export function fetchSalesDateRangeAction(filter) {
  let qs = "?";
  if (filter && filter.dateFilter && filter.dateFilter.week.from) {
    qs += "DateStart=" + filter.dateFilter.week.from.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.week.to) {
    if (qs) qs += "&";
    qs += "DateEnd=" + filter.dateFilter.week.to.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.week.fromAp) {
    if (qs) qs += "&";
    qs += "DateStartAp=" + filter.dateFilter.week.fromAp.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.week.toAp) {
    if (qs) qs += "&";
    qs += "DateEndAp=" + filter.dateFilter.week.toAp.toISOString();
  }

  return makeFetch(SET_SALES_MASTER_RANGE, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_VIEWAPP +
        "v1/Sales/GetSalesFromDateRange" +
        qs
    );
  });
}

export function fetchSalesDateRangeApAction(filter) {
  let qs = "?";
  if (filter && filter.dateFilter && filter.dateFilter.weekAp.from) {
    qs += "DateStart=" + filter.dateFilter.weekAp.from.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.weekAp.to) {
    if (qs) qs += "&";
    qs += "DateEnd=" + filter.dateFilter.weekAp.to.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.weekAp.fromAp) {
    if (qs) qs += "&";
    qs += "DateStartAp=" + filter.dateFilter.weekAp.fromAp.toISOString();
  }
  if (filter && filter.dateFilter && filter.dateFilter.weekAp.toAp) {
    if (qs) qs += "&";
    qs += "DateEndAp=" + filter.dateFilter.weekAp.toAp.toISOString();
  }

  return makeFetch(SET_SALES_MASTER_RANGE, {}, () => {
    return GET(
      process.env.REACT_APP_URL_API_VIEWAPP +
        "v1/Sales/GetSalesFromDateRangeAp" +
        qs
    );
  });
}

// export function fetchUserCfgViewApp() {
//   return makeFetch(SET_CFG_USER_VIEWAPP, {}, () => {
//     return GET(
//       process.env.REACT_APP_URL_API_VIEWAPP + "v1/UserWeb/GetUserwebAbilitation"
//     );
//   });
// }

// export const MONITOR_FETCH_DATA = "MONITOR_FETCH_DATA";
// function MonitorStartFetchData(filter) {
//   return {
//     type: MONITOR_FETCH_DATA,
//     filter
//   };
// }

//export const MONITOR_RECEIVE_DATA = "MONITOR_RECEIVE_DATA";
// function MonitorReceiveData(filter, json) {
//   return {
//     type: MONITOR_RECEIVE_DATA,
//     filter,
//     posts: json,
//     receivedAt: Date.now()
//   };
// }
