import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";

import { SaveStateApp, SetInfoCart, fetchCfgShopSpo } from "../redux/actions";

import { GET, POST, DELETE, PUT } from "request-helper";

import Viewport from "../components/Viewport";
import Footer from "../components/Footer";
import LayoutWithHead from "../components/LayoutWithHead";
import CardProduct from "../components/Product/CardProduct";
import GridProduct from "../components/Product/GridProduct";
import { roundTwoDigit } from "../utility/utility";
import DialogLoader from "../components/DialogLoader";
import Logo from "../components/Logo";
import DialogConfirm from "../components/DialogConfirm";
import DeliverySummary from "../components/Product/DeliverySummary";

import {
  Slide,
  Tooltip,
  OutlinedInput,
  Drawer,
  Divider,
  ListItemIcon,
  Typography,
  Zoom,
  useScrollTrigger,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Moment from "react-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faList,
  faArrowsAltH,
  faTimes,
  faStoreAlt,
  faCalendarAlt,
  faMapMarkedAlt,
  faTags,
  faShoppingBag,
  faPercent,
  faHeart,
  faShoppingCart,
  faListAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { FormattedNumber } from "react-intl";
import ContentLeftBar from "../components/Product/ContentLeftBar";
import { NotificationManager } from "react-notifications";

library.add(fas);
library.add(fab);

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: "90%",
    // height: "60%",
    maxHeight: "60%",
    // minHeight: "200px",
    left: "5% !important",
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  buttomDepartment: {
    marginLeft: theme.spo.mXs,
    marginRight: theme.spo.mXs,
    marginBottom: theme.spo.mXs,
    boxShadow: "0px 0px 12px 0px #ccc",
    borderRadius: "50px",
    // marginBottom: "15px",
    "&:hover": {
      transform: "translateY(-4px)",
      backgroundColor: "#ccc",
      transition: "transform 0.2s",
    },
  },

  shoOnMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  showOnSm: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  tabSectionProduct: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarXs: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },

  widthLeft: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
    },
  },

  widthLeftCollapsed: {
    width: "50px",
  },

  widthRightFull: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 50px)",
    },
    width: "calc(100% - 250px)",
  },
  widthRight: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "calc(100% - 50px)",
  },

  drawerWidth: {
    width: 250,
  },
}));

const Product = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const productLabel = "Prodotti";
  const favoriteLabel = "Preferiti";
  const buyLabel = "Acquistati da te";
  const promoLabel = "Promozioni";
  //desc = 0 , asc = 1
  const orderByObject = [
    {
      description: "A-Z crescente",
      order: 1,
      field: "Name",
    },
    {
      description: "A-Z decrescente",
      order: 0,
      field: "Name",
    },
    {
      description: "Prezzo crescente",
      order: 1,
      field: "Price",
    },
    {
      description: "Prezzo decrescente",
      order: 0,
      field: "Price",
    },
    {
      description: "Marca crescente",
      order: 1,
      field: "Marca",
    },
    {
      description: "Marca decrescente",
      order: 0,
      field: "Marca",
    },
  ];

  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isReload, setIsReload] = React.useState(false);
  const [typingTimeout, setTypingTimeout] = React.useState({
    value: "",
    idTimeOut: 0,
  });

  //TODO da togliere , prendere i dati dal CartReducers
  const [deliveryDetail, setDeliveryDetail] = React.useState(null);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = React.useState(
    false
  );
  const [altriRisultati, setAltriRisultati] = React.useState(false);

  const [currentValueTab, setCurrentValueTab] = React.useState(0);

  const [listFavorite, setListFavorite] = React.useState(null);

  const [loadingProduct, setLoadingProduct] = React.useState(false);

  //true card false grid
  const [layoutCard, setLayoutCard] = React.useState(true);

  const [collapsedLeft, setCollapsedLeft] = React.useState(true);

  const [orderBySelect, setOrderBySelect] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);

  const [listBrand, setListBrand] = React.useState(null);
  const [brand, setBrand] = React.useState({});

  const allDepartment = {
    idCatWeb: "ALL",
    desCatWeb: "TUTTE",
    icon: "clipboard-list",
  };
  const [listDepartment, setListDepartment] = React.useState(null);
  const [department, setDepartment] = React.useState(allDepartment);
  const [
    departmentFirstSelection,
    setDepartmentFirstSelection,
  ] = React.useState(allDepartment);

  const [menuListCategory, setMenuListCategory] = React.useState(null);
  const [listCategory, setListCategory] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [idCategory, setIdCategory] = React.useState(null);

  const [textSearch, setTextSearch] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  var refDepartment = useRef();
  const [anchorElHamburgerMenu, setAnchorElHamburgerMenu] = React.useState(
    null
  );
  const [drawer, setDrawer] = React.useState({ left: false });

  var searchFilterObjectEmpty = {
    idDepartment: null, //int?
    idcategory: null, //int?
    orderBy: null, //int?
    orderField: null, //string
    textSearch: null, //string
    filterBrand: null, //string
    typeOfSearch: 0, //int
    pageNumber: 0, //int?
    pageSize: 20, //int?
  };

  var textValueCurrent = "";

  const [searchFilterProduct, setSearchFilterProduct] = React.useState(null);

  const [listProduct, setListProduct] = React.useState(null);

  const [currentTitle, setCurrentTitle] = React.useState(productLabel);

  window.onscroll = () => {
    // const {
    //   loadUsers,
    //   state: { error, isLoading, hasMore },
    // } = this;

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    // if (error || isLoading || !hasMore) return;
    if (!searchFilterProduct) return;
    if (disableInfiniteScroll) return;
    // Checks that the page has scrolled to the bottom
    if (
      // window.innerHeight + document.documentElement.scrollTop ===
      // document.documentElement.offsetHeight
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 300
    ) {
      if (!loadingProduct) {
        document.activeElement.blur();
        setSearchFilterProduct({
          ...searchFilterProduct,
          ...{ pageNumber: searchFilterProduct.pageNumber + 1 },
        });
      }
      console.log("scroll");
    }
  };

  //TODO da togliere , prendere i dati dal CartReducers
  const fetchDeliveryDetail = () => {
    let param = {
      shopId: props.store.CartReducers.shopId,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/GetCartDeliveryDetail",
      null,
      param,
      true
    ).then((data) => {
      setDeliveryDetail(data);
    });
  };

  const fetchListFavorite = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListfavoriteByUser",
      null,
      {},
      true
    ).then((data) => {
      setListFavorite(data);
    });
  };

  const fetchListDepartment = () => {
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListDepartment",
      searchFilterProduct,
      null,
      true
    ).then((data) => {
      if (data == null || !data.length) return;
      let dep = [...[allDepartment], ...data];
      setListDepartment(dep);
    });
  };

  const fetchListCategory = (depart, forMenu) => {
    let dep = depart || department;
    if (dep.idCatWeb == null) {
      setListCategory(null);
      return;
    }

    let filter = searchFilterProduct || searchFilterObjectEmpty;
    filter.idcategoryForMenuList = dep.idCatWeb;
    setSearchFilterProduct(filter);

    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListCategory",
      filter,
      null,
      true
    ).then((data) => {
      if (data && data.length) {
        data.push({ idCatWeb: "ALL", desCatWeb: "Tutti i prodotti" });
      }
      if (!forMenu) {
        setListCategory(data);
        return;
      }
      setMenuListCategory({
        department: dep,
        category: data,
      });
    });
  };

  const fetchListProduct = () => {
    setLoadingProduct(true);
    if (props.store.CartReducers && props.store.CartReducers.shopId) {
      let param = {
        codPv: props.store.CartReducers.shopId,
        search: searchFilterProduct,
      };
      POST(
        process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListProduct",
        param,
        null,
        true
      ).then((data) => {
        if (data && !data.length) {
          setDisableInfiniteScroll(true);
        }

        setLoadingProduct(false);
        var dataToDisplay = [];
        if (searchFilterProduct.pageNumber == 0) {
          dataToDisplay = data;
        } else {
          dataToDisplay = [...listProduct, ...data];
        }
        setListProduct(dataToDisplay);

        if (data.length > 0 && dataToDisplay && dataToDisplay.length > 100) {
          setAltriRisultati(true);
          setDisableInfiniteScroll(true);
        } else setAltriRisultati(false);
      });
    }
  };

  const fetchListBrand = () => {
    //if (props.store.CartReducers && props.store.CartReducers.shopId) {

    POST(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ListBrandForSelectedFilter",
      searchFilterProduct,
      null,
      true
    ).then((data) => {
      setListBrand(data);
    });
    //}
  };

  const fetchAddToCart = (product) => {
    let param = {
      shopId: props.store.CartReducers.shopId,
      Product: {
        idProduct: product.coD_RIORD,
        quantity: product.qtaBase,
        price: roundTwoDigit(product.promoPrice || product.price),
        amount: roundTwoDigit(
          (product.promoPrice || product.price) * product.qtaBase
        ),
      },
    };
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/addToCart",
      param,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      param.Product.vwWebSpoProduct = product;
      props.store.CartReducers.webSpoProductInCart.push(param.Product);
      props.SetInfoCart(props.store.CartReducers);
      NotificationManager.success(
        product.description + " aggiunto nel carrello"
      );
    });
  };

  const fetchRemoveFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveFromCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD,
      null,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      props.store.CartReducers.webSpoProductInCart = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => {
          return c.idProduct !== product.coD_RIORD;
        }
      );
      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const fetchRemoveAllFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveAllFromCart?shopId=" +
        props.store.CartReducers.shopId,
      null,
      null,
      true
    ).then((data) => {
      props.store.CartReducers.webSpoProductInCart = [];
      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const fetcUpdateQuantityProduct = (product, quantity) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/UpdateQuantityToCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD +
        "&quantity=" +
        quantity,
      null,
      null,
      true
    ).then((data) => {
      var res = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => c.idProduct === product.coD_RIORD
      );
      res[0].quantity = quantity;

      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const fetchSetFavorite = (product) => {
    let res = listFavorite.filter((c) => c.idProduct == product.coD_RIORD);
    var promise = null;
    var isRemove = false;
    //il prodotto è già tra i favoriti devo toglierlo
    if (res[0]) {
      isRemove = true;
      promise = DELETE(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/RemoveProductToFavorite?idProduct=" +
          product.coD_RIORD,
        null,
        null,
        true
      );
    }
    //il prodotto non è tra i favoriti devo aggiungerlo
    else {
      promise = POST(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/AddProductToFavorite",
        // { idProduct: product.coD_RIORD },
        { idProduct: product.coD_RIORD },
        null,
        true
      );
    }
    promise.then((data) => {
      let list = null;
      if (isRemove) {
        list = listFavorite.filter((c) => c.idProduct != product.coD_RIORD);
      } else {
        list = listFavorite;
        list.push({ idProduct: product.coD_RIORD });
      }
      setListFavorite([...list]);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseHamburgerMenu = () => {
    setAnchorElHamburgerMenu(null);
  };

  const onChangeSelectCategory = (e) => {
    let cat = listCategory.filter((c) => {
      return c.idCatWeb == e.target.value;
    });
    if (!cat) return;
    clickMenuCategory(cat[0]);
  };

  const clickMenuCategory = (category) => {
    setAnchorEl(null);
    let idDepartment = null;
    let cat = null;
    if (category) {
      setDepartment(menuListCategory.department);
      setCategory(category);
      setListCategory(menuListCategory.category);
      setIdCategory(category.idCatWeb);

      cat = category.idCatWeb == "ALL" ? null : category.idCatWeb;
      idDepartment = menuListCategory.department.idCatWeb;
    } else {
      setCategory(null);
      setIdCategory(null);
      setListCategory(null);
    }

    setCollapsedLeft(false);

    setListBrand(null);
    setBrand({});
    setTextSearch("");
    setOrderBy(null);
    // setOrderField(null);

    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{
        idDepartment: idDepartment,
        idcategory: cat,
        orderBy: null,
        orderField: null,
        textSearch: null,
        filterBrand: null,
        pageNumber: 0,
      },
    });
  };

  const handleClickDepartment = (department, event) => {
    setDepartmentFirstSelection(department);
    //ho cliccato su  TUTTE
    if (department.idCatWeb == "ALL") {
      clickMenuCategory();
      setDepartment(department);
      return;
    }

    setListCategory(null);
    setMenuListCategory(null);

    fetchListCategory(department, true);
    var ct = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(ct);
    }, 200);
  };

  const onChangeTypeOfSearch = (idType) => {
    let currentTitle = "";
    switch (idType) {
      case 0:
        currentTitle = productLabel;
        break;
      case 1:
        currentTitle = favoriteLabel;
        break;
      case 2:
        currentTitle = buyLabel;
        break;
      case 3:
        currentTitle = promoLabel;
        break;
    }
    setCurrentTitle(currentTitle);
    setListBrand(null);
    setBrand({});
    setTextSearch("");
    setOrderBy(null);
    setCategory(null);
    setListCategory(null);
    setDepartment(allDepartment);
    setIdCategory(null);
    if (searchFilterProduct == null)
      searchFilterProduct = searchFilterObjectEmpty;
    searchFilterProduct.pageNumber = 0;
    searchFilterProduct.typeOfSearch = idType;
    searchFilterProduct.filterBrand = null;
    searchFilterProduct.idDepartment = null;
    searchFilterProduct.idcategory = null;
    searchFilterProduct.orderBy = null;
    searchFilterProduct.orderField = null;
    setSearchFilterProduct({ ...searchFilterProduct });
  };

  const calculateTotalCart = () => {
    var total =
      props.store.CartReducers &&
      props.store.CartReducers.webSpoProductInCart &&
      props.store.CartReducers.webSpoProductInCart.reduce &&
      props.store.CartReducers.webSpoProductInCart.reduce(
        (accumul, prod) => accumul + roundTwoDigit(prod.price * prod.quantity),
        0
      );
    if (total == undefined || total == null) total = 0;
    return total;
  };

  const redirectToWizardCart = () => {
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart");
  };

  function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }

  useEffect(() => {
    if (!props.store.CartReducers) return;
    if (props.store.CartReducers.shopId) {
      searchFilterObjectEmpty.shopId = props.store.CartReducers.shopId;

      //lo fa solo la prima volta cioè se listDepartment è null
      //e carica al primo avvio i dati
      if (listDepartment == null) {
        if (
          props.store &&
          props.store.AppReducers &&
          props.store.AppReducers.stateSaved &&
          props.store.AppReducers.stateSaved.product
        ) {
          //il filtro iniziale se c'è uno stato salvato deve essere null
          setSearchFilterProduct(null);
          fetchListFavorite();
        } else {
          setSearchFilterProduct(searchFilterObjectEmpty);
          // fetchListDepartment();
          fetchListFavorite();
        }
        if (
          props.store &&
          props.store.AppReducers &&
          !props.store.AppReducers.SpoShopCfg
        ) {
          props.fetchCfgShopSpo(props.store.CartReducers.shopId);
        }
      }
      if (deliveryDetail == null) fetchDeliveryDetail();
    } else {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    }
  }, [props.store.CartReducers]);

  useEffect(() => {
    if (props.store.CartReducers == null) {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    }

    //resume old state
    if (
      props.store.AppReducers &&
      props.store.AppReducers.stateSaved &&
      props.store.AppReducers.stateSaved.product
    ) {
      let oldState = props.store.AppReducers.stateSaved.product;
      if (oldState) {
        setTimeout(() => {
          var lastFilter = oldState.searchFilterProduct;
          if (lastFilter == null || lastFilter == undefined)
            lastFilter = searchFilterObjectEmpty;
          lastFilter.pageNumber = 0;
          if (!lastFilter.shopId)
            lastFilter.shopId = props.store.CartReducers.shopId;
          setIsReload(true);
          setTextSearch(oldState.textSearch);
          setIdCategory(oldState.idCategory);
          setDepartment(oldState.department);
          setCategory(oldState.category);
          setListCategory(oldState.listCategory);
          setCurrentValueTab(oldState.currentValueTab);
          setOrderBy(oldState.orderBy);
          setOrderBySelect(oldState.orderBySelect);
          setCollapsedLeft(oldState.collapsedLeft);
          setLayoutCard(oldState.layoutCard);
          setListBrand(oldState.listBrand);
          setBrand(oldState.brand);
          setSearchFilterProduct(lastFilter);
          setIsReload(false);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    if (category == null) return;
    if (isReload) return;
    setBrand({});
    let cat = category.idCatWeb == "ALL" ? null : category.idCatWeb;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ idcategory: cat, filterBrand: null, pageNumber: 0 },
    });
  }, [category]);

  useEffect(() => {
    if (orderBy == null) return;
    if (isReload) return;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ orderBy: orderBy.order, orderField: orderBy.field },
    });
  }, [orderBy]);

  // useEffect(() => {
  //     if (textSearch == null) return;
  //   searchFilterProduct.pageNumber = 0;
  //   setSearchFilterProduct({
  //     ...searchFilterProduct,
  //     ...{ textSearch: textSearch },
  //   });

  // }, [textSearch]);

  const changeText = (val) => {
    if (val == null) return;
    searchFilterProduct.pageNumber = 0;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ textSearch: val },
    });
  };

  useEffect(() => {
    if (brand == null || Object.keys(brand).length === 0) return;
    if (isReload) return;
    let brandname = "";
    for (var name in brand) {
      if (brand[name]) {
        if (brandname) brandname += ",";
        brandname += name;
      }
    }
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ filterBrand: brandname, pageNumber: 0 },
    });
  }, [brand]);

  useEffect(() => {
    if (searchFilterProduct == null) return;
    fetchListProduct();
    if (category) fetchListBrand();
    fetchListDepartment();
    setDisableInfiniteScroll(false);

    if (!isEmpty) {
      //salvo lo stato attuale della pagina prodotti
      var stateSaved = {
        searchFilterProduct,
        textSearch,
        idCategory,
        category,
        listCategory,
        department,
        brand,
        listBrand,
        orderBy,
        orderBySelect,
        collapsedLeft,
        layoutCard,
        currentValueTab,
      };
      props.SaveStateApp({
        page: "product",
        state: stateSaved,
      });
    }
    setIsEmpty(false);
  }, [searchFilterProduct]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  return (
    <div>
      <DialogLoader show={!listProduct}></DialogLoader>
      {listProduct && (
        <div>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorElHamburgerMenu}
            keepMounted
            open={Boolean(anchorElHamburgerMenu)}
            onClose={handleCloseHamburgerMenu}
          >
            {deliveryDetail && (
              <div>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.deliveryMode}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faMapMarkedAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.deliveryAddress}{" "}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faStoreAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.shopInfo} {deliveryDetail.shopAddress}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography>
                    <Moment format="DD-MM-YYYY">
                      {deliveryDetail.dateDelivery}
                    </Moment>{" "}
                    <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      {deliveryDetail.timeStart}
                    </Moment>{" "}
                    -{" "}
                    <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
                      </ListItemIcon>
                      {deliveryDetail.timeEnd}
                    </Moment>
                  </Typography>
                </MenuItem>
                <Divider></Divider>
              </div>
            )}
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(0);
                handleCloseHamburgerMenu();
              }}
            >
              {productLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(1);
                handleCloseHamburgerMenu();
              }}
            >
              {favoriteLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(2);
                handleCloseHamburgerMenu();
              }}
            >
              {buyLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(3);
                handleCloseHamburgerMenu();
              }}
            >
              {promoLabel}
            </MenuItem>
          </Menu>
          {loadingProduct && (
            <Fab
              style={{
                position: "fixed",
                bottom: theme.spacing(2),
                right: theme.spacing(2),
              }}
            >
              <CircularProgress></CircularProgress>
            </Fab>
          )}
          <LayoutWithHead
            onClickToggle={(e) => {
              setAnchorElHamburgerMenu(e.currentTarget);
            }}
            rowLogoLeft={<Logo></Logo>}
            rowLogoRight={
              <Grid container>
                <DeliverySummary
                  deliveryDetail={deliveryDetail}
                ></DeliverySummary>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  style={{
                    textAlign: "right",
                    paddingRight: theme.spo.pSm,
                  }}
                >
                  {/* <DialogConfirm
                    title={"Elimina"}
                    text={"Sei sicuro di voler svuotare il carrello?"}
                  >
                    {(confirm) => (
                      <Tooltip title={"Svuota il carrello"}>
                        <IconButton
                          disabled={
                            !props.store.CartReducers ||
                            !props.store.CartReducers.webSpoProductInCart ||
                            props.store.CartReducers.webSpoProductInCart
                              .length == 0
                          }
                          aria-label="status"
                          onClick={confirm(() => {
                            fetchRemoveAllFromCart();
                          })}
                          style={theme.spo.product.iconHeadTrash}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </DialogConfirm> */}
                  <Tooltip title={"Vai al carrello"}>
                    <IconButton
                      disabled={
                        !props.store.CartReducers ||
                        !props.store.CartReducers.webSpoProductInCart ||
                        props.store.CartReducers.webSpoProductInCart.length == 0
                      }
                      aria-label="status"
                      onClick={() => {
                        props.history.push(
                          process.env.REACT_APP_BASE_PATH_ROUTE + "/Summary"
                        );
                      }}
                      style={theme.spo.product.iconHeadTrash}
                    >
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </IconButton>
                  </Tooltip>
                  {props.store &&
                  props.store.AppReducers &&
                  props.store.AppReducers.SpoCfg &&
                  props.store.AppReducers.SpoCfg.showCountPiece ? (
                    <span style={theme.spo.product.iconHeadTrash}>
                      {props.store.CartReducers.webSpoProductInCart.length} pz
                    </span>
                  ) : (
                    <span style={theme.spo.product.iconHeadTrash}>
                      <FormattedNumber
                        value={roundTwoDigit(calculateTotalCart())}
                        style="currency"
                        currency="EUR"
                        currencySign="accounting"
                      />
                    </span>
                  )}
                </Grid>
                <Grid
                  container
                  style={{
                    marginTop: theme.spo.mSm,
                    marginLeft: theme.spo.mXs,
                  }}
                >
                  <Grid item xs={11}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={theme.spo.product.textBoxSearch}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Ricerca
                      </InputLabel>
                      <OutlinedInput
                        style={{ borderRadius: "50px" }}
                        label="Ricerca"
                        value={textSearch}
                        onChange={(e) => {
                          if (typingTimeout.idTimeOut) {
                            clearTimeout(typingTimeout.idTimeOut);
                          }
                          textValueCurrent = e.target.value;
                          setTypingTimeout({
                            value: e.target.value,
                            idTimeOut: setTimeout(() => {
                              // changeText(typingTimeout.value);
                              changeText(textValueCurrent);
                            }, 2000),
                          });
                          setTextSearch(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setTextSearch("");
                                changeText("");
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            }
            rowBar={
              <Grid container justify="center" style={{ textAlign: "center" }}>
                <Grid item xs={12} className={classes.showOnSm}>
                  <FormControl fullWidth>
                    <InputLabel id="department-select-label">
                      Scegli il reparto
                    </InputLabel>
                    <Select
                      style={{ marginRight: theme.spo.pSm }}
                      // className={}
                      labelId="department-select-label"
                      id="department-select"
                      value={department.idCatWeb}
                      onChange={(e) => {
                        var sel = listDepartment.filter((c) => {
                          return c.idCatWeb == e.target.value;
                        });
                        handleClickDepartment(sel[0], e);
                      }}
                    >
                      {listDepartment &&
                        listDepartment.map((c, i) => {
                          return (
                            <MenuItem
                              value={c.idCatWeb}
                              key={"list-department-menu" + c.idCatWeb}
                            >
                              {c.desCatWeb}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.shoOnMd}>
                  {listDepartment &&
                    listDepartment.map &&
                    listDepartment.map((c) => {
                      return (
                        <Button
                          key={"list-department-btn" + c.idCatWeb}
                          style={{
                            ...(department && department.idCatWeb == c.idCatWeb
                              ? theme.spo.product.departmentSelected
                              : theme.spo.product.departmentUnSelected),
                            display: "inline",
                            padding: 0,
                          }}
                          className={classes.buttomDepartment}
                          variant="outlined"
                          onClick={(e) => {
                            handleClickDepartment(c, e);
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              style={{
                                ...(department &&
                                department.idCatWeb == c.idCatWeb
                                  ? theme.spo.product.IconDeparmentSelected
                                  : theme.spo.product.IconDeparment),
                              }}
                              icon={["fas", c.icon]}
                            />
                          </div>
                          <div>{c.desCatWeb}</div>
                        </Button>
                      );
                    })}
                  <Menu
                    PopoverClasses={{ paper: classes.popoverPaper }}
                    id="simple-menu"
                    anchorEl={refDepartment.current}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    // transformOrigin={{
                    //   vertical: "bottom",
                    //   horizontal: "left",
                    // }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        sm={2}
                        style={theme.spo.product.departmentPopLeft}
                      >
                        <h3>{departmentFirstSelection.desCatWeb}</h3>
                        <div>
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              fontSize: "50px",
                              marginBottom: theme.spo.mMd,
                            }}
                            icon={["fas", departmentFirstSelection.icon]}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={10}
                        style={{ padding: theme.spo.mSm }}
                      >
                        <Grid container>
                          {menuListCategory &&
                            menuListCategory.category &&
                            menuListCategory.category.map &&
                            menuListCategory.category.map((c) => {
                              return (
                                // <MenuItem
                                //   key={"menu-list-cat" + c.idCatWeb}
                                //   onClick={() => {
                                //     clickMenuCategory(c);
                                //   }}
                                // >
                                //   {c.desCatWeb}
                                // </MenuItem>
                                <Grid item xs="12" sm="4">
                                  <Button
                                    style={{
                                      justifyContent: "flex-start",
                                      textAlign: "left",
                                      fontSize: "12px",
                                    }}
                                    fullWidth
                                    key={"menu-list-cat" + c.idCatWeb}
                                    onClick={() => {
                                      clickMenuCategory(c);
                                    }}
                                  >
                                    {c.desCatWeb}
                                  </Button>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Menu>
                </Grid>
                <div
                  id="refMenuDepartment"
                  ref={refDepartment}
                  style={{ width: "100%" }}
                ></div>
              </Grid>
            }
          >
            <Viewport
              viewEnable={{ left: true, right: true }}
              // viewEnable={{ left: true }}
              iconLeft={<FontAwesomeIcon icon={faListAlt} />}
              collapsedLeft={collapsedLeft}
              contentLeft={
                <ContentLeftBar
                  idCategory={idCategory}
                  brand={brand}
                  setBrand={setBrand}
                  listBrand={listBrand}
                  listCategory={listCategory}
                  onChangeSelectCategory={onChangeSelectCategory}
                ></ContentLeftBar>
              }
              iconRight={<FontAwesomeIcon icon={faEye} />}
              contentRight={
                // <Box style={{ padding: theme.spo.pSm }}>
                <Grid container justify="flex-start" spacing={2}>
                  {props.store.CartReducers.webSpoProductInCart &&
                  props.store.CartReducers.webSpoProductInCart.length > 0 ? (
                    <>
                      {props.store.CartReducers.webSpoProductInCart.map((e) => {
                        return (
                          <CardProduct
                            gridSize={{ xs: 12 }}
                            key={"card-product" + e.vwWebSpoProduct.coD_RIORD}
                            product={e.vwWebSpoProduct}
                            newProduct={fetchAddToCart}
                            removeProduct={fetchRemoveFromCart}
                            updateQuantity={fetcUpdateQuantityProduct}
                            cart={props.store.CartReducers}
                            setFavorite={fetchSetFavorite}
                            favorites={listFavorite}
                          ></CardProduct>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        padding: theme.spo.pSm,
                      }}
                    >
                      Nessun prodotto nel carrello
                    </div>
                  )}
                </Grid>
                // </Box>
              }
              forceDrawerRight={true}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.spo.product.backgroundPage,
                }}
              >
                <Grid container style={theme.spo.product.headerListProduct}>
                  <Grid item xs={5} md={2}>
                    <Box
                      style={{
                        paddingLeft: theme.spo.pSm,
                        paddingTop: theme.spo.pSm,
                      }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Box
                          style={{
                            marginRight: theme.spo.mSm,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setLayoutCard(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faThLarge}
                              style={{
                                fontSize:
                                  theme.spo.product.sizeIconDisplayProduct,
                                color:
                                  theme.spo.product.backgroundColorIconPrimary,
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box className={classes.shoOnMd}>
                          <IconButton
                            onClick={() => {
                              setLayoutCard(false);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              style={{
                                fontSize:
                                  theme.spo.product.sizeIconDisplayProduct,
                                color:
                                  theme.spo.product.backgroundColorIconPrimary,
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={1} md={7}>
                    <Grid
                      container
                      // xs={12}
                      style={{
                        marginTop: theme.spo.mSm,
                      }}
                      justify="center"
                    >
                      {/* Tab menu */}
                      <Tabs
                        className={classes.tabSectionProduct}
                        value={currentValueTab}
                        onChange={(event, value) => {
                          setCurrentValueTab(value);
                        }}
                        style={{
                          // width: "650px",
                          width: "100%",
                          color: theme.spo.darkGray,
                        }}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          // label={"Prodotti"}
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faTags} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {productLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(0);
                          }}
                        />
                        <Tab
                          //label="Preferiti"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faHeart} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {favoriteLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(1);
                          }}
                        />
                        <Tab
                          //label="Acquistati da te"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faShoppingBag} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {buyLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(2);
                          }}
                        />
                        <Tab
                          // label="Promozioni"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faPercent} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {promoLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(3);
                          }}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    style={{
                      paddingRight: theme.spo.mSm,
                      textAlign: "right",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="category-select-label">
                        Ordina per
                      </InputLabel>
                      <Select
                        // className={}
                        labelId="category-select-label"
                        id="category-select"
                        value={orderBySelect}
                        onChange={(e) => {
                          setOrderBySelect(e.target.value);
                          setOrderBy(orderByObject[e.target.value]);
                        }}
                      >
                        {orderByObject.map((c, i) => {
                          return (
                            <MenuItem
                              key={"order-by" + Math.floor(Math.random() * 999)}
                              value={i}
                            >
                              {c.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={theme.spo.product.breadcrumbBar}>
                    <Box>
                      <h6 style={{ margin: 0 }}>
                        {currentTitle}
                        {department && " > " + department.desCatWeb}
                        {category && " > " + category.desCatWeb}
                      </h6>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-start"
                  spacing={2}
                  style={
                    {
                      //paddingTop: theme.spo.pMd,
                    }
                  }
                >
                  {listProduct &&
                    listProduct.map &&
                    listProduct.map((c) => {
                      return (
                        // <Grid container style={{ margin: theme.spo.mSm }}>

                        layoutCard ? (
                          <CardProduct
                            key={"card-product" + c.coD_RIORD}
                            product={c}
                            newProduct={fetchAddToCart}
                            removeProduct={fetchRemoveFromCart}
                            updateQuantity={fetcUpdateQuantityProduct}
                            cart={props.store.CartReducers}
                            setFavorite={fetchSetFavorite}
                            favorites={listFavorite}
                          ></CardProduct>
                        ) : (
                          <GridProduct
                            key={"card-product" + c.coD_RIORD}
                            product={c}
                            newProduct={fetchAddToCart}
                            removeProduct={fetchRemoveFromCart}
                            updateQuantity={fetcUpdateQuantityProduct}
                            cart={props.store.CartReducers}
                            setFavorite={fetchSetFavorite}
                            favorites={listFavorite}
                          ></GridProduct>
                        )
                      );
                    })}
                </Grid>
                {altriRisultati && (
                  <Grid
                    container
                    justify={"center"}
                    style={{ margin: theme.spo.mMd }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSearchFilterProduct({
                          ...searchFilterProduct,
                          ...{
                            pageNumber: searchFilterProduct.pageNumber + 1,
                          },
                        });
                      }}
                    >
                      Altri Risultati
                    </Button>
                  </Grid>
                )}
                {!loadingProduct && (
                  <ScrollTop>
                    <Fab
                      color="secondary"
                      size="small"
                      aria-label="scroll back to top"
                      style={{
                        position: "fixed",
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollTop>
                )}
              </Grid>
            </Viewport>
            {/* <div
                className={
                  !collapsedLeft
                    ? classes.widthLeft
                    : classes.widthLeftCollapsed
                }
                style={{
                  marginRight: theme.spo.mSm - 5,
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              >
                <Grid item xs={12}>
                  <Paper
                    style={{
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                    }}
                  >
                    <Box className={classes.hideExpandBarMd}>
                      <IconButton
                        color="primary"
                        aria-label="menu laterale"
                        onClick={() => {
                          setCollapsedLeft(!collapsedLeft);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowsAltH} />
                      </IconButton>
                    </Box>
                    <Box className={classes.hideExpandBarXs}>
                      <IconButton
                        color="primary"
                        aria-label="menu laterale"
                        onClick={toggleDrawer("left", true)}
                        // style={{ position: "fixed" }}
                      >
                        <FontAwesomeIcon icon={faArrowsAltH} />
                      </IconButton>
                    </Box>
                    <Drawer
                      anchor={"left"}
                      open={drawer["left"]}
                      onClose={toggleDrawer("left", false)}
                    >
                      <div
                        className={classes.drawerWidth}
                        role="presentation"
                        // onClick={toggleDrawer("left", false)}
                        onKeyDown={toggleDrawer("left", false)}
                      ></div>
                    </Drawer>
                    <Slide
                      direction="right"
                      in={!collapsedLeft}
                      mountOnEnter
                      unmountOnExit
                      // timeout={{ appear: 5000, enter: 100, exit: 100 }}
                    >
                      <div className={classes.hideExpandBarMd}>
                        <ContentLeftBar
                          idCategory={idCategory}
                          brand={brand}
                          setBrand={setBrand}
                          listBrand={listBrand}
                          listCategory={listCategory}
                          onChangeSelectCategory={onChangeSelectCategory}
                        ></ContentLeftBar>
                      </div>
                    </Slide>
                  </Paper>
                </Grid>
              </div>

              <Grid
                container
                className={
                  !collapsedLeft ? classes.widthRightFull : classes.widthRight
                }
                spacing={3}
                style={{
                  //width: sizeRight,
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              ></Grid> */}
          </LayoutWithHead>

          <Footer></Footer>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {
  SaveStateApp,
  SetInfoCart,
  fetchCfgShopSpo,
})(Product);

// export default Product;
