/**************************************************************
 *                  import external package
 /************************************************************/
import React, { useState, useEffect, useContext } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogWithContent = (props) => {
  //bool
  const props_open = props.open;
  //function
  const props_onClose = props.onClose;
  const props_title = props.title;
  const props_contentText = props.contentText;
  //react object
  const props_content = props.content;
  //array di oggetti
  //{onClick:()=>{},
  //label:""}
  const props_action = props.action;

  return (
    <Dialog
      open={props_open}
      onClose={() => {
        props_onClose();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props_title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props_contentText}</DialogContentText>
        {props_content}
      </DialogContent>
      <DialogActions>
        {props_action.map((e) => {
          return (
            <Button
              key={"btn" + Math.floor(Math.random() * 9999)}
              onClick={() => {
                e.onClick();
              }}
              color="primary"
            >
              {e.label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default DialogWithContent;
