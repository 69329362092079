import React, { useState, useEffect, useContext } from "react";

import Logo from "../components/Logo";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Barcode from "react-barcode";
import { Grid, Paper } from "@material-ui/core";
import Footer from "../components/Footer";

const BarCode = (props) => {
  const theme = useTheme();
  return (
    <div
      style={{
        // backgroundColor: theme.spo.product.backgroundPage,
        paddingBottom: theme.spo.pSm,
      }}
    >
      {/* container testata */}
      <Grid
        container
        p={3}
        style={{
          backgroundColor: theme.spo.product.backgroundPaper,
        }}
      >
        {/* riga uno logo / search */}
        <Grid item xs={12} style={theme.spo.product.mainHeader}>
          <Grid container>
            <Grid item xs={4} xl={3} style={{ padding: theme.spo.pMd }}>
              <Logo></Logo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        spacing={3}
        style={{ marginTop: theme.spo.mMd }}
      >
        <Grid items></Grid>
        <Grid item xs={12} lg={4}>
          <Paper
            style={{
              //   padding: theme.spo.pMd,
              textAlign: "center",
              //   height: "230px",
            }}
          >
            <Barcode
              width={3}
              height={200}
              value={props.match.params.barcode}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4} style={{ height: "100%" }}>
          <Paper
            style={{
              padding: theme.spo.pSm,
              height: "100%",
            }}
          >
            <h4>Codice spesa</h4>
            <p>
              Questo codice ci permette di identificare gli articoli della tua
              spesa.<br></br> Mostralo al nostro operatore prima di effettuare
              il pagamento.
            </p>
          </Paper>
        </Grid>
      </Grid>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default BarCode;
