import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const InfoTolleranceField = (props) => {
  const theme = useTheme();

  const props_showPercIncr = props.showPercIncr;
  const props_percIncrementWeight = props.percIncrementWeight;
  return (
    <>
      <Tooltip
        arrow
        title={
          <div style={{ fontSize: "14px;" }}>
            <Typography>
              Il peso e il prezzo dei prodotti a peso variabile possono, per
              loro natura, variare in eccesso o in difetto rispetto a quanto
              dichiarato sul sito.
            </Typography>{" "}
            <Typography>
              Viene cautelativamente mostrato il prezzo calcolato in base ad un
              arrotondamento per eccesso{" "}
              {props_showPercIncr && (
                <span>del {props_percIncrementWeight}% </span>
              )}
              e verrà addebitato l'esatto importo solo al momento del ritiro
              della spesa.
            </Typography>
            <Typography style={{ paddingTop: theme.spo.mts }}>
              Ai sensi della Legge n.123 del 3 Agosto 2017, dal 1 Gennaio 2018
              tutti i sacchetti biodegradabili utilizzati per i prodotti a peso
              variabile saranno obbligatori e a carico dei consumatori. Come
              sopra, l'importo effettivo verrà addebitato solo al momento della
              consegna dell'ordine in base al numero di sacchetti effettivamente
              utilizzati.
            </Typography>
          </div>
        }
      >
        <IconButton disabled={false}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </IconButton>
      </Tooltip>
      Tolleranza
      {props_showPercIncr && <span>del {props_percIncrementWeight}% </span>} sui
      prodotti a peso
    </>
  );
};

export default InfoTolleranceField;
