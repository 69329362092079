import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { geocodeAddress, parseAddressComponents } from "../geocodeAddress";
import DialogConfirm from "../DialogConfirm";

const useStyles = makeStyles((theme) => ({}));

const DialogAddress = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const props_Title = props.title;
  const props_labelNext = props.labelNext || "Salva";
  const props_showDialog = props.showDialog;
  const props_hideDialog = props.hideDialog || function () {};
  const props_saveAddress = props.saveAddress || function () {};
  const props_geocodedAddress = props.geocodedAddress || function () {};
  const props_showNote =
    props.showNote == null || props.showNote == undefined
      ? true
      : props.showNote;
  const [geocodedAddress, setGeocodedAddress] = React.useState(null);
  const [flagAddress, setFlagAddress] = React.useState(null);
  const [address, setAddress] = React.useState({
    address: "",
    city: "",
    postalCode: "",
    province: "",
  });

  const fetchGeocodeAddress = () => {
    let fullAddress =
      address.address +
      " " +
      address.city +
      " " +
      address.postalCode +
      " " +
      address.province;

    geocodeAddress(fullAddress, (data) => {
      setGeocodedAddress(data);
      props_geocodedAddress(data);
    });
  };

  const handleCloseDialog = () => {
    setFlagAddress(false);
    props_hideDialog();
  };

  useEffect(() => {
    if (props_showDialog == null) return;
    setFlagAddress(props_showDialog);
  }, [props_showDialog]);

  return (
    <Dialog
      open={flagAddress}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">{props_Title}</DialogTitle>
      <DialogContent>
        <div style={{}}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                required={true}
                error={!address.address}
                style={{ width: "100%" }}
                label="Indirizzo"
                value={address.address}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    ...{ address: e.target.value },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                error={!address.city}
                style={{ width: "100%" }}
                label="Citta"
                value={address.city}
                onChange={(e) => {
                  setAddress({ ...address, ...{ city: e.target.value } });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required={true}
                error={!address.postalCode}
                style={{ width: "100%" }}
                label="Cap"
                value={address.postalCode}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    ...{ postalCode: e.target.value },
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required={true}
                error={!address.province}
                style={{ width: "100%" }}
                label="Provincia"
                value={address.province}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    ...{ province: e.target.value },
                  });
                }}
              />
            </Grid>
            {props_showNote && (
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Note ( note aggiuntive per la consegna es: numero interno ,numero scala o piano etc...   )"
                  value={address.noteAddress}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      ...{ noteAddress: e.target.value },
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Annulla
        </Button>

        <DialogConfirm
          title={"Conferma indirizzo"}
          text={
            <>
              {geocodedAddress &&
                geocodedAddress[0] &&
                geocodedAddress[0].formatted_address && (
                  <div>
                    <div>Hai selezionato il seguente indirizzo </div>
                    <div>
                      <div style={{ textAlign: "center", fontSize: "18px" }}>
                        {geocodedAddress &&
                          geocodedAddress[0].formatted_address}
                      </div>
                    </div>
                    <div>Vuoi confermarlo?</div>
                  </div>
                )}
            </>
          }
        >
          {(confirm) => (
            <Button
              onClick={(e) => {
                if (
                  !address.address ||
                  !address.city ||
                  !address.postalCode ||
                  !address.province
                )
                  return;
                fetchGeocodeAddress();
                confirm(() => {
                  handleCloseDialog();
                  props_saveAddress(address);
                  // saveAddress();
                })(e);
              }}
              color="primary"
              autoFocus
            >
              {props_labelNext}
            </Button>
          )}
        </DialogConfirm>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAddress;
