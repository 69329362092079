import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { UserManager } from "oidc-client";

import { loginConfig } from "../loginConfig";

import { SetInfoCart } from "../redux/actions";
import { roundTwoDigit } from "../utility/utility";

import { GET, POST, DELETE, PUT } from "request-helper";

import DialogWithContent from "../components/DialogWithContent";
import Footer from "../components/Footer";
import Grid from "@material-ui/core/Grid";
import LayoutWithHead from "../components/LayoutWithHead";
import DetailProduct from "../components/Product/DetailProduct";
import ImageView from "../components/ImageView";
import DialogLoader from "../components/DialogLoader";
import Logo from "../components/Logo";

import { Button, Tabs, Tab, Box, IconButton, Tooltip } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  hideOnSm: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  hideOnMd: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const Detail = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [productDetail, setProductDetail] = React.useState(null);
  const [currentTabInfo, setCurrentTabInfo] = React.useState(0);
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [isAnonymous, setIsAnonymous] = React.useState(false);
  const [flagDialogLogin, setFlagDialogLogin] = React.useState(false);

  const redirectToLoginIS4 = () => {
    var mgr = new UserManager(loginConfig);
    mgr.signinRedirect();
  };

  useEffect(() => {
    if (props.store.CartReducers == null) return;
    if (!props.store.CartReducers.webSpoProductInCart) setIsAnonymous(true);
  }, [props.store.CartReducers]);

  const fetchDetailProduct = () => {
    let param = {
      idProduct: props.match.params.idDetail,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetProducDetail",
      null,
      param,
      true
    ).then((data) => {
      setProductDetail(data);
      setIsFavorite(data.isFavorite);
    });
  };

  const addToCart = (product) => {
    if (isAnonymous) {
      setFlagDialogLogin(true);
      return;
    }
    let param = {
      shopId: props.store.CartReducers.shopId,
      Product: {
        idProduct: product.coD_RIORD,
        quantity: 1,
        price: roundTwoDigit(product.promoPrice || product.price),
        amount: roundTwoDigit(
          (product.promoPrice || product.price) * product.qtaBase
        ),
      },
    };
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/addToCart",
      param,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      props.store.CartReducers.webSpoProductInCart.push(param.Product);
      props.SetInfoCart(props.store.CartReducers);
    });
  };
  const removeFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveFromCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD,
      null,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      props.store.CartReducers.webSpoProductInCart = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => {
          return c.idProduct !== product.coD_RIORD;
        }
      );
      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const updateQuantityProduct = (product, quantity) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/UpdateQuantityToCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD +
        "&quantity=" +
        quantity,
      null,
      null,
      true
    ).then((data) => {
      var res = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => c.idProduct === product.coD_RIORD
      );
      res[0].quantity = quantity;

      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const setFavorite = (product) => {
    var promise = null;
    var isRemove = false;
    //il prodotto è già tra i favoriti devo toglierlo
    if (isFavorite) {
      isRemove = true;
      promise = DELETE(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/RemoveProductToFavorite?idProduct=" +
          product.coD_RIORD,
        null,
        null,
        true
      );
    }
    //il prodotto non è tra i favoriti devo aggiungerlo
    else {
      promise = POST(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/AddProductToFavorite",
        // { idProduct: product.coD_RIORD },
        { idProduct: product.coD_RIORD },
        null,
        true
      );
    }
    promise.then((data) => {
      let list = null;
      if (isRemove) {
        setIsFavorite(false);
      } else {
        setIsFavorite(true);
      }
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }
  useEffect(() => {
    fetchDetailProduct();
  }, []);

  return (
    <div>
      <DialogLoader show={!productDetail}></DialogLoader>
      {productDetail && (
        <div style={{ paddingBottom: theme.spo.pSm }}>
          <LayoutWithHead
            allowAnonymous={isAnonymous}
            rowLogoLeft={<Logo></Logo>}
            rowBar={
              <Grid item xs={6}>
                <Tooltip title="Torna agli acquisti">
                  <IconButton
                    style={{ fontSize: "45px", padding: 0 }}
                    color="primary"
                    onClick={() => {
                      if (isAnonymous) {
                        props.history.push(
                          process.env.REACT_APP_BASE_PATH_ROUTE + "/productShop"
                        );
                        return;
                      }
                      props.history.push(
                        process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
          >
            <Grid item xs={2} className={classes.hideOnSm}>
              {" "}
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <ImageView
                    Images={productDetail.vwWebProductImage}
                  ></ImageView>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: theme.spo.pSm }}>
                  <DetailProduct
                    noCounter={isAnonymous}
                    noFavorite={isAnonymous}
                    product={productDetail}
                    cart={props.store.CartReducers}
                    newProduct={addToCart}
                    removeProduct={removeFromCart}
                    updateQuantity={updateQuantityProduct}
                    setFavorite={setFavorite}
                    isFavorite={isFavorite}
                  ></DetailProduct>
                  <DialogWithContent
                    key={"dl-privacy"}
                    open={flagDialogLogin}
                    onClose={() => {
                      setFlagDialogLogin(false);
                    }}
                    contentText={
                      <div>
                        <div>
                          Per poter aggiungere al carrello devi essere
                          registrato.
                        </div>
                      </div>
                    }
                    title=""
                    action={[
                      {
                        onClick: () => {
                          setFlagDialogLogin(false);
                        },
                        label: "Annulla",
                      },
                      {
                        onClick: () => {
                          setFlagDialogLogin(false);
                          redirectToLoginIS4();
                        },
                        label: "Accedi o registrati",
                      },
                    ]}
                  ></DialogWithContent>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} className={classes.hideOnSm}></Grid>
            <Grid container style={{ backgroundColor: "#fff" }}>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Tabs
                  value={currentTabInfo}
                  onChange={(event, newValue) => {
                    setCurrentTabInfo(newValue);
                  }}
                  aria-label="simple tabs example"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab label="Caratteristiche" />
                  <Tab label="Ingredienti" />
                  <Tab label="Informazioni" />
                </Tabs>
                <TabPanel value={currentTabInfo} index={0}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productDetail.caratteristiche,
                    }}
                  ></div>
                </TabPanel>
                <TabPanel value={currentTabInfo} index={1}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productDetail.ingredienti,
                    }}
                  ></div>
                </TabPanel>
                <TabPanel value={currentTabInfo} index={2}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productDetail.informazioni,
                    }}
                  ></div>
                </TabPanel>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </LayoutWithHead>

          <Footer></Footer>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, { SetInfoCart })(Detail);
