import React, { useState, useEffect, useContext } from "react";
import CustomField from "../../components/CustomField";
import validationInvoice from "../../Pages/WizardUserValidation";

import {
  Grid,
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

const InvoiceData = (props) => {
  const props_haveInvoice = props.haveInvoice;
  const props_setHaveInvoice = props.setHaveInvoice;
  const props_userData = props.userData;
  const props_setUserData = props.setUserData;
  const props_setNotSaved = props.setNotSaved || function () {};
  const props_allCountry = props.allCountry;
  const props_showSwitch =
    props.showSwitch == undefined ? true : props.showSwitch;

  return (
    <div>
      {props_showSwitch && (
        <Grid item xs={12}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={props_haveInvoice}
                  onChange={(event) => {
                    props_setHaveInvoice(event.target.checked);
                  }}
                  name="haveInvoice"
                />
              }
              label="Attiva / Disattiva dati di fatturazione"
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="invoiceBusinessName"
              inputProps={{ maxLength: 50 }}
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceBusinessName.required
              }
              error={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceBusinessName.error
              }
              style={{ width: "100%" }}
              label="Ragione sociale"
              value={props_userData.invoiceBusinessName}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceBusinessName: e.target.value },
                });
                props_setNotSaved(true);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <CustomField
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceVATPrefix.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceVATPrefix.error()}
              style={{ width: "100%" }}
            >
              {(props) => (
                <FormControl {...props}>
                  <InputLabel id="shop-select-label">Stato</InputLabel>
                  <Select
                    // disabled={false}
                    labelId="shop-select-label"
                    id="invoiceVATPrefix"
                    value={props_userData.invoiceVATPrefix}
                    onChange={(e) => {
                      props_setUserData({
                        ...props_userData,
                        ...{ invoiceVATPrefix: e.target.value },
                      });
                      props_setNotSaved(true);
                    }}
                  >
                    {props_allCountry &&
                      props_allCountry.map((c) => {
                        return <MenuItem value={c}>{c}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              )}
            </CustomField>
          </Grid>

          <Grid item xs={5}>
            <CustomField
              id="invoiceVATNumber"
              inputProps={{ maxLength: 11 }}
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceVATNumber.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceVATNumber.error()}
              style={{ width: "100%" }}
              label="Partita iva"
              value={props_userData.invoiceVATNumber}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceVATNumber: e.target.value },
                });
                props_setNotSaved(true);
              }}
              onBlur={(e) => {
                // fetchCheckVat();
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>

          <Grid item xs={5}>
            <CustomField
              id="fiscalCode"
              disabled={!props_haveInvoice}
              inputProps={{ maxLength: 16 }}
              required={
                validationInvoice(props_haveInvoice, props_userData).fiscalCode
                  .required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).fiscalCode.error()}
              style={{ width: "100%" }}
              label="Codice Fiscale"
              value={props_userData.fiscalCode}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ fiscalCode: e.target.value.toUpperCase() },
                });
                props_setNotSaved(true);
              }}
              onBlur={(e) => {
                //fetchCheckFiscalCode();
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="invoiceAddress"
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceAddress.required
              }
              error={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceAddress.error
              }
              style={{ width: "100%" }}
              label="Indirizzo"
              value={props_userData.invoiceAddress}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceAddress: e.target.value },
                });
                props_setNotSaved(true);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomField
              id="invoiceCity"
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData).invoiceCity
                  .required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceCity.error()}
              style={{ width: "100%" }}
              label="Citta"
              value={props_userData.invoiceCity}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceCity: e.target.value },
                });
                props_setNotSaved(true);
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>
          <Grid item xs={6}>
            <CustomField
              id="invoiceZipCode"
              inputProps={{ maxLength: 5 }}
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceZipCode.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceZipCode.error()}
              style={{ width: "100%" }}
              label="Cap"
              value={props_userData.invoiceZipCode}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceZipCode: e.target.value },
                });
                props_setNotSaved(true);
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>
          <Grid item xs={6}>
            <CustomField
              id="invoiceProvince"
              inputProps={{ maxLength: 10 }}
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceProvince.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceProvince.error()}
              style={{ width: "100%" }}
              label="Provincia"
              value={props_userData.invoiceProvince}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceProvince: e.target.value },
                });
                props_setNotSaved(true);
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>

          <Grid item xs={8}>
            <CustomField
              id="invoicePECMailAddress"
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoicePECMailAddress.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoicePECMailAddress.error()}
              style={{ width: "100%" }}
              label="PEC Partita Iva"
              value={props_userData.invoicePECMailAddress}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoicePECMailAddress: e.target.value },
                });

                props_setNotSaved(true);
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>

          <Grid item xs={4}>
            <CustomField
              id="invoiceSDICode"
              inputProps={{ maxLength: 10 }}
              disabled={!props_haveInvoice}
              required={
                validationInvoice(props_haveInvoice, props_userData)
                  .invoiceSDICode.required
              }
              error={validationInvoice(
                props_haveInvoice,
                props_userData
              ).invoiceSDICode.error()}
              style={{ width: "100%" }}
              label="Codice SDI"
              value={props_userData.invoiceSDICode}
              onChange={(e) => {
                props_setUserData({
                  ...props_userData,
                  ...{ invoiceSDICode: e.target.value },
                });
                props_setNotSaved(true);
              }}
            >
              {(props) => <TextField {...props} />}
            </CustomField>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceData;
