import AppTheme from "./AppTheme";
import AppThemeCoal from "./AppThemeCoal";
const themeFactory = () => {
  switch (process.env.REACT_APP_THEME) {
    case "coal":
      return AppThemeCoal;
    default:
      return AppTheme;
  }
};

export default themeFactory;
