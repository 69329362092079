import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import LayoutWithHead from "../components/LayoutWithHead";
import Logo from "../components/Logo";
import Footer from "../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCreditCard,
  faPeopleCarry,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({}));

const PaymentOk = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <DialogTitle id="simple-dialog-title">Pagamento terminato</DialogTitle>
      <DialogContent>
        <div>
          <div>Il pagamento è andato a buon fine .</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          id="dialogResumeExpiredCart"
          onClick={() => {
            props.history.push(
              process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
            );
          }}
          color="primary"
          autoFocus
        >
          Prosegui
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PaymentOk;
