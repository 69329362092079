/**
 * Contiene le informazioni della pagina corrente
 */

import {
  SET_NAVIGATION_TITLE,
  SET_FILTER_ITEMS_ON_PAGINATION,
  SET_LATERAL_MENU,
  GET_FILTER_CONFIGURATION
} from "../../commonActionTypes";

const initialState = {
  title: "",
  itemsOnPaginationFilter: 20,
  lateralMenu: []
};

const PageReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LATERAL_MENU: {
      state = { ...state, ...{ lateralMenu: action.payload } };
      return state;
    }
    case SET_NAVIGATION_TITLE: {
      state = { ...state, ...{ title: action.payload } };
      return state;
    }
    case SET_FILTER_ITEMS_ON_PAGINATION: {
      state = { ...state, ...{ itemsOnPaginationFilter: action.payload } };
      return state;
    }
    case GET_FILTER_CONFIGURATION: {
      let v = action.posts;
      state = { ...state, ...{ FilterPageConfiguration: v } };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default PageReducers;
