import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

const DialogLoader = (props) => {
  const props_show = props.show || false;
  {
    /*
  dialog loader e logo
   <Dialog open={props_show}>
<Logo style={{ visibility: "hidden" }}></Logo>
<div
  style={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0 }}
></div>
<div
  style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
>
  <div style={{ textAlign: "center" }}>
    <CircularProgress />
    <div>caricamento...</div>
  </div>
</div>
</Dialog> */
  }
  return (
    <Dialog open={props_show}>
      <div style={{ margin: "10px" }}>
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
        <div>caricamento...</div>
      </div>
    </Dialog>
  );
};
export default DialogLoader;
