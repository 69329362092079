import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import DialogWithContent from "../DialogWithContent";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";

const PrivacyConditions = (props) => {
  const props_checked = props.checked;
  const props_setAccepted = props.setAccepted;
  const props_errorUnchecked = props.errorUnchecked || false;

  const [infoOpen, setInfoOpen] = React.useState(false);

  return (
    <div>
      <FormControl
        required
        error={props_errorUnchecked && props_checked == false}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={props_checked}
              onChange={(event) => {
                props_setAccepted(event.target.checked);
              }}
              name={"privacyConditions"}
            />
          }
          label={
            <div>
              Ho letto e accettato l'{" "}
              <Link
                href="#"
                onClick={() => {
                  setInfoOpen(true);
                }}
              >
                informativa sulla Privacy
              </Link>
            </div>
          }
        />
        <FormHelperText>
          {props_errorUnchecked && props_checked == false
            ? "Per proseguire devi accettare l'informativa privacy"
            : ""}{" "}
        </FormHelperText>
      </FormControl>
      {/* <div>
        Do il consenso al trattamento dei dati per attività di profilazione
      </div> */}
      <div>
        {/* <Link
        href="#"
        onClick={() => {
          setInfoOpen(true);
        }}>
          Leggi l' informativa sulla Privacy
        </Link> */}
      </div>
      <DialogWithContent
        open={infoOpen}
        onClose={() => {
          setInfoOpen(false);
        }}
        contentText={
          <div
            dangerouslySetInnerHTML={{
              __html: props.store.AppReducers.SpoCfg.privacyConditions,
            }}
          ></div>
        }
        title="Informativa Privacy"
        action={[
          {
            onClick: () => {
              setInfoOpen(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, {})(PrivacyConditions);
