import { SET_FILTER_MASTER } from "../actionTypes";
import {
  firstDayOfACurrentWeek,
  LastDayOfACurrentWeek
} from "../../utility/utility";

function calculateWeekApInitial() {
  let firstDayOfThisWeek = firstDayOfACurrentWeek();
  let lastDayOfCurrentWeek = LastDayOfACurrentWeek();

  //data della settimana scorsa
  let dateFromPrecWeek = new Date(
    firstDayOfThisWeek.setDate(firstDayOfThisWeek.getDate() - 7)
  );

  let dateToPrecWeek = new Date(
    lastDayOfCurrentWeek.setDate(lastDayOfCurrentWeek.getDate() - 7)
  );

  var dateTmp = new Date(dateFromPrecWeek);
  //data settimana scorsa anno precedente
  let dd = new Date(dateTmp.setFullYear(dateTmp.getFullYear() - 1));
  let datefromAp = firstDayOfACurrentWeek(dd);

  dateTmp = new Date(dateToPrecWeek);
  dd = new Date(dateTmp.setFullYear(dateTmp.getFullYear() - 1));
  let dateToAp = LastDayOfACurrentWeek(dd);

  return {
    from: dateFromPrecWeek,
    to: dateToPrecWeek,
    fromAp: datefromAp,
    toAp: dateToAp
  };
}

const initialState = {
  dateFilter: {
    day: {
      from: new Date()
    },
    week: {
      from: firstDayOfACurrentWeek(),
      to: LastDayOfACurrentWeek()
    },
    weekAp: calculateWeekApInitial()
  }
};

const FilterSalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_MASTER: {
      state = { ...state, ...action.payload };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default FilterSalesReducer;
