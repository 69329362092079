import React, { useState, useEffect, useContext } from "react";
import { useField } from "formik";
import { GET, POST, DELETE, PUT } from "request-helper";

const RedirectManageUser = (props) => {
  const op = props.match.params.operation;
  const id = props.match.params.userid;
  const fetchDeleteUser = () => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/User/UnsubscribeUser?userGuid=" +
        id,
      null,
      null,
      true
    ).then((data) => {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/main");
    });
  };
  useEffect(() => {
    if (op == "delete") {
      fetchDeleteUser();
    }
  }, []);
  return <div>delete</div>;
};

export default RedirectManageUser;
