import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { SetInfoCart, SaveStateApp } from "../redux/actions";

import { GET, POST } from "request-helper";

import SpoLayoutBg from "../components/SpoLayoutBg";
import Footer from "../components/Footer";
import Stepper from "../components/Stepper";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    height: 140,
  },
  title: {
    color: theme.palette.primary.main,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  iconSize: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 150,
    },
    //fontSize: 180,
    color: "#cccccc",
  },
}));

const SpoHome = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [dialogPaymentCart, setDialogPaymentCart] = React.useState(null);
  const [dialogResumeCart, setDialogResumeCart] = React.useState(null);
  const [dialogActiveCart, setDialogActiveCart] = React.useState(null);

  const redirectToWizardCart = () => {
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart");
  };

  const redirectToProduct = () => {
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/product");
  };

  const fetchResumableCart = (redirectToWizard) => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetResumableCart",
      null,
      null,
      true
    ).then((data) => {
      props.SaveStateApp({
        page: "product",
        state: null,
      });
      if (!data || data.expired == undefined) {
        //non esiste nessun carrello recuperabile vado ad iniziarne uno nuovo
        if (redirectToWizard) redirectToWizardCart();
        return;
      }

      if (data.lastIdStatus == 1010) {
        //il pagamento è stato effettua non è riuscito ed è stato marcato come fallito , il pagamento non andrà mai a buon fine il flusso continua
        //normalmente in maniera tale che viene creato un nuvo carrello e nel continuo del codice verifico e viene chiesto all'utente se il carrello
        //è scaduto e se vuole recuperarlo
      }
      if (data.lastIdStatus == 1000) {
        if (data.transactionStatus == "reject") {
          //il pagamento è stato effettua non è riuscito ed è stato marcato come fallito , il pagamento non andrà mai a buon fine il flusso continua
          //normalmente in maniera tale che viene creato un nuvo carrello e nel continuo del codice verifico e viene chiesto all'utente se il carrello
          //è scaduto e se vuole recuperarlo
          //setDialogPaymentCart(true);
          //return;
        }
        if (data.transactionStatus == "ok") {
          //il pagamento è stato recuperato
          redirectToWizardCart();
          return;
        }

        if (data.transactionStatus == "ko") {
          setDialogPaymentCart(data);
          return;
        }
      }

      //il carrello esiste ed è scaduto chiedo all'utente
      if (data.expired) {
        setDialogResumeCart(data);
        return;
      }
      //il carrello esiste e non è scaduto setto come carrello corrente quello recuperato e vado alla pagina prodotti
      setDialogActiveCart(data);
      return;
    });
  };

  const fetchResumableKoCart = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetResumableKoCart",
      null,
      null,
      true
    ).then((data) => {
      props.SaveStateApp({
        page: "product",
        state: null,
      });
      if (!data || data.expired == undefined) {
        //non esiste nessun carrello recuperabile vado ad iniziarne uno nuovo
        redirectToWizardCart();
        return;
      }

      if (data.lastIdStatus == 1010) {
        //il pagamento è stato effettua non è riuscito ed è stato marcato come fallito , il pagamento non andrà mai a buon fine il flusso continua
        //normalmente in maniera tale che viene creato un nuvo carrello e nel continuo del codice verifico e viene chiesto all'utente se il carrello
        //è scaduto e se vuole recuperarlo
      }
      if (data.lastIdStatus == 1000) {
        if (data.transactionStatus == "reject") {
          //il pagamento è stato effettua non è riuscito ed è stato marcato come fallito , il pagamento non andrà mai a buon fine il flusso continua
          //normalmente in maniera tale che viene creato un nuvo carrello e nel continuo del codice verifico e viene chiesto all'utente se il carrello
          //è scaduto e se vuole recuperarlo
          //setDialogPaymentCart(true);
          //return;
        }
        if (data.transactionStatus == "ok") {
          //il pagamento è stato recuperato
          redirectToWizardCart();
        }

        if (data.transactionStatus == "ko") {
          //
        }
      }

      //il carrello esiste ed è scaduto chiedo all'utente
      if (data.expired) {
        props.SetInfoCart(dialogResumeCart);
        redirectToWizardCart();
        return;
      }
      //il carrello esiste e non è scaduto setto come carrello corrente quello recuperato e vado alla pagina prodotti
      props.SetInfoCart(data);
      redirectToProduct();
      return;
    });
  };

  const resumeExpiredCart = () => {
    props.SetInfoCart(dialogResumeCart);
    //se resume è a true devo forzare il recupero
    redirectToWizardCart();
  };

  const handleClickSpesa = () => {
    fetchResumableCart(true);
  };

  useEffect(() => {
    if (props.UserReducers.profile) {
      fetchResumableCart();
    }
  }, [props.UserReducers]);

  return (
    <div>
      <SpoLayoutBg
        titleHeadText={"Home"}
        codeImage={"MainHome"}
        mainContainer={
          <Box>
            {/* style={{ height: "350px" }} */}
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  style={{ marginTop: theme.spo.pMd }}
                >
                  <Grid container justify={"center"}>
                    <Grid item xs={7}>
                      <Paper
                        id="newPrenotationHome"
                        className={classes.hover}
                        onClick={handleClickSpesa}
                        style={{
                          textAlign: "center",
                          padding: "15px",
                          //minWidth: "270px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className={classes.iconSize}
                          // style={{
                          //   [theme.breakpoints.up("xs")]: {
                          //     fontSize: 180,
                          //   },
                          //   //fontSize: 180,
                          //   color: "#cccccc",
                          // }}
                        />
                        <h3 className={classes.subTitle}>Nuova Prenotazione</h3>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  style={{ marginTop: theme.spo.pMd }}
                >
                  <Grid container justify={"center"}>
                    <Grid item xs={7}>
                      <Paper
                        id="myOrderHome"
                        className={classes.hover}
                        onClick={() => {
                          props.history.push(
                            process.env.REACT_APP_BASE_PATH_ROUTE + "/Orders"
                          );
                        }}
                        style={{
                          textAlign: "center",
                          padding: "15px",
                          //minWidth: "270px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBoxOpen}
                          className={classes.iconSize}
                          // style={{ fontSize: 180, color: "#cccccc" }}
                        />
                        <h3 className={classes.subTitle}>I tuoi Ordini</h3>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="simple-dialog-title"
              open={dialogResumeCart != null}
            >
              <DialogTitle id="simple-dialog-title">
                Carrello scaduto
              </DialogTitle>
              <DialogContent>
                <div>
                  <div>
                    Abbiamo trovato un carrello scaduto non confermato vuoi
                    recuperarlo?
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  id="dialogStartNewCart"
                  onClick={() => {
                    // dialogResumeCart.renew = true;
                    // props.SetInfoCart(dialogResumeCart);
                    redirectToWizardCart();
                  }}
                  color="primary"
                >
                  Inizia Nuovo
                </Button>
                <Button
                  id="dialogResumeExpiredCart"
                  onClick={() => {
                    resumeExpiredCart();
                  }}
                  color="primary"
                  autoFocus
                >
                  Recupera
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="simple-dialog-title"
              open={dialogActiveCart != null}
            >
              <DialogTitle id="simple-dialog-title">
                Carrello non confermato
              </DialogTitle>
              <DialogContent>
                <div>
                  <div>
                    Abbiamo trovato un carrello non confermato vuoi recuperarlo?
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  id="dialogStartNewNotConfirmed"
                  onClick={() => {
                    // dialogResumeCart.renew = true;
                    // props.SetInfoCart(dialogActiveCart);
                    redirectToWizardCart();
                  }}
                  color="primary"
                >
                  Inizia Nuovo
                </Button>
                <Button
                  id="dialogResumeNotConfirmed"
                  onClick={() => {
                    props.SetInfoCart(dialogActiveCart);
                    redirectToProduct();
                  }}
                  color="primary"
                >
                  Recupera
                </Button>
              </DialogActions>
            </Dialog>

            {/* <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              open={dialogPaymentCart}
            >
              <DialogTitle id="simple-dialog-title">
                Verifica transazione di pagamento
              </DialogTitle>
              <DialogContent>
                <div>
                  <div>
                    Abbiamo trovato una transazione di pagamento che necessita
                    una verifica.
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  id="dialogStartVerifyTransation"
                  onClick={() => {
                    setDialogresumeKo(true);
                  }}
                  color="primary"
                >
                  VERIFICA TRANSAZIONE
                </Button>
              </DialogActions>
            </Dialog> */}

            {/* <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              open={dialogResumeKo}
            >
              <DialogTitle id="simple-dialog-title">
                Verifica transazione di pagamento
              </DialogTitle>
              <DialogContent>
                <div>
                  <div>
                    Se la transazione andrà a buon fine procederete ad un nuovo
                    carrello . Altrimenti verrà recuperato il carrello n. xxx
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  id="dialogStartVerifyTransation"
                  onClick={() => {
                    fetchResumableKoCart();
                  }}
                  color="primary"
                >
                  Prosegui
                </Button>
              </DialogActions>
            </Dialog> */}

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              aria-labelledby="simple-dialog-title"
              open={dialogPaymentCart != null}
            >
              <DialogTitle id="simple-dialog-title">
                Verifica transazione
              </DialogTitle>
              <DialogContent>
                <div>
                  <Stepper
                    items={[
                      {
                        title: "Verifica transazione",
                        content: (
                          <div>
                            {dialogPaymentCart && (
                              <>
                                La transazione di pagamento del carrello{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  n° {dialogPaymentCart.id} creato il{" "}
                                  {new Date(
                                    dialogPaymentCart.dateCreate
                                  ).toLocaleDateString()}{" "}
                                  di importo {dialogPaymentCart.total}€{" "}
                                </span>
                                risulta in elaborazione e va verificata.
                              </>
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Recupero transazione",
                        content: (
                          <div>
                            {dialogPaymentCart && (
                              <>
                                <div>La verifica verrà completata.</div>
                                <div>
                                  Se non sarà possibile confermare il pagamento,
                                  il carrello{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    n° {dialogPaymentCart.id} creato il{" "}
                                    {new Date(
                                      dialogPaymentCart.dateCreate
                                    ).toLocaleDateString()}{" "}
                                    di importo {dialogPaymentCart.total}€{" "}
                                  </span>{" "}
                                  verrà reso di nuovo disponibile per completare
                                  gli acquisti e il pagamento.
                                </div>
                              </>
                            )}
                          </div>
                        ),
                        onNext: () => {
                          fetchResumableKoCart();
                        },
                      },
                    ]}
                  ></Stepper>
                </div>
              </DialogContent>
              {/* <DialogActions>
                <Button
                  id="dialogStartNewCart"
                  onClick={() => {
                    // dialogResumeCart.renew = true;
                    // props.SetInfoCart(dialogResumeCart);
                    redirectToWizardCart();
                  }}
                  color="primary"
                >
                  Inizia Nuovo
                </Button>
                <Button
                  id="dialogResumeExpiredCart"
                  onClick={() => {
                    resumeExpiredCart();
                  }}
                  color="primary"
                  autoFocus
                >
                  Recupera
                </Button>
              </DialogActions> */}
            </Dialog>
          </Box>
        }
        bottomTitle={"Spesa online"}
        bottomSubtitle={
          "Il servizio di spesa online ti permette di prenotare la tua spesa direttamente da casa, scegliendo tra consegna a domicilio o ritiro nel punto vendita "
        }
      >
        <Footer></Footer>
      </SpoLayoutBg>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  SetInfoCart,
  SaveStateApp,
})(SpoHome);
