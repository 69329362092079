import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import { UserManager } from "oidc-client";

import { connect } from "react-redux";
import { SetUserLogged } from "../redux/actions";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Box,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Hidden,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import ToolbarLogin, { LoginToolbarRedux } from "toolbar-login";

import {
  //   addDefaultUnAuthorized,
  //   addDefaultFail,
  getCounterRequest,
} from "request-helper";

import "react-notifications/lib/notifications.css";

import { loginConfig } from "../loginConfig";
import DialogLoader from "../components/DialogLoader";

const useStyles = makeStyles((theme) => ({}));

const Layout = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const props_onClickToggle = props.onClickToggle;
  const props_handleSearchTextFieldTb = props.handleSearchTextFieldTb;
  const props_title = props.title;
  const props_fullLoaded = props.fullLoaded || function () {};
  const props_allowAnonymous =
    props.allowAnonymous == undefined || props.allowAnonymous == null
      ? false
      : props.allowAnonymous;

  var config = loginConfig;

  const requestCounter = getCounterRequest();
  requestCounter.onStartLoading = () => {
    setLoading(true);
  };
  requestCounter.onEndLoading = () => {
    setLoading(false);
  };

  //funzione per ottenere le informazioni loggate dell'utente
  function handleGetUserApi(userReducer) {
    if (userReducer) {
      return userReducer != null
        ? {
            name: userReducer.name,
            role: userReducer.role,
          }
        : null;
    } else {
      handleLoginApi();
    }
  }

  //funzione per effettuare la login
  function handleLoginApi() {
    // var mgr = new UserManager(config);
    // mgr.signinRedirect();
    // props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/main");
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/login");
  }

  //funzione per effettuare la logout
  function handleLogoutApi(logoutId, callback) {
    //chiamata asincrona alle api per verificare se l'utente è loggato
    var mgr = new UserManager(config);
    mgr.signoutRedirect();
    callback({});
  }

  // addDefaultUnAuthorized(() => {
  //   var mgr = new UserManager(config);
  //   mgr.signinRedirect();
  // });

  // addDefaultFail((error) => {
  //   NotificationManager.error("Si è verificato un errore", "Attenzione");
  // });

  function onClickToggle(e) {
    if (props_onClickToggle) props_onClickToggle(e);
  }

  const redirectToLoginIS4 = () => {
    var mgr = new UserManager(loginConfig);
    mgr.signinRedirect();
  };

  useEffect(() => {
    //handleGetUserApi();
  }, [props.UserReducers]);

  function renderOnLogged() {
    if (props_allowAnonymous) return <Box>{props.children}</Box>;
    if (props.UserReducers && props.UserReducers.profile) {
      props_fullLoaded();
      return <Box>{props.children}</Box>;
    }
    return null;
  }
  const itemMenu = () => {
    return (
      <div>
        <MenuItem
          onClick={() => {
            props.history.push(
              process.env.REACT_APP_BASE_PATH_ROUTE + "/Account"
            );
          }}
        >
          Dati Utente
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.history.push(
              process.env.REACT_APP_BASE_PATH_ROUTE + "/Orders"
            );
          }}
        >
          I tuoi ordini
        </MenuItem>
      </div>
    );
  };
  return (
    <div>
      <div>
        {!props_allowAnonymous ? (
          <LoginToolbarRedux
            getUserApi={handleGetUserApi}
            getLoginApi={handleLoginApi}
            getLogoutApi={handleLogoutApi}
            onClickToggle={onClickToggle}
            userReducers={props.UserReducers.profile}
            showMyUserArea={false}
            itemMenu={itemMenu()}
            title={
              props_title ||
              (props.PageReducers && props.PageReducers.title
                ? props.PageReducers.title
                : "")
            }
            handleSearch={props_handleSearchTextFieldTb}
            styleToolBar={theme.spo.toolBar}
            // styleAppBar={{ minHeight: "25px" }}
          ></LoginToolbarRedux>
        ) : (
          <AppBar position="static" style={{}}>
            <Toolbar id="back-to-top-anchor" style={theme.spo.toolBar}>
              <Hidden mdUp>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={props_onClickToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Typography variant="h6">{props_title}</Typography>
              <div style={{ flexGrow: 1 }} />
              <Link
                href="#"
                onClick={() => {
                  redirectToLoginIS4();
                }}
              >
                Accedi o registrati
              </Link>
            </Toolbar>
          </AppBar>
        )}
        {renderOnLogged()}
        <DialogLoader show={loading}></DialogLoader>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    UserReducers: state.UserReducers,
    PageReducers: state.PageReducers,
  };
};

// export default withRouter(Layout);
export default withRouter(
  connect(mapStateToProps, {
    SetUserLogged,
  })(Layout)
);
