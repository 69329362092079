import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const ImageView = (props) => {
  const theme = useTheme();

  const props_images = props.Images || [];

  const [imageSelected, setImageSelected] = React.useState(null);

  useEffect(() => {
    if (!props.Images) return;
    if (props.Images[0]) {
      setImageSelected(props.Images[0].immagine);
    }
  }, [props.Images]);

  return (
    <Grid container style={{ height: "320px" }}>
      <Grid item xs={2} style={{ height: "100%" }}>
        {props_images.map((c) => {
          return (
            <div
              style={{
                width: "50px",
                height: "50px",
                marginBottom: theme.spo.mXs,
                textAlign: "center",
                height: "100%",
              }}
            >
              <img
                style={{
                  height: "53px",
                }}
                src={"data:image/png;base64," + c.immagine}
                onClick={() => {
                  setImageSelected(c.immagine);
                }}
              ></img>
            </div>
          );
        })}
      </Grid>
      <Grid item xs={10}>
        <Paper
          style={theme.spo.imageView.containerImage}
          style={{ overflow: "hidden", textAlign: "center", height: "100%" }}
        >
          {imageSelected ? (
            <img
              style={{
                // maxHeight: "100%",
                // maxWidth: "100%",
                height: "100%",
                ...theme.spo.imageView.imagePreview1,
              }}
              src={"data:image/png;base64," + imageSelected}
            ></img>
          ) : (
            <div
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                padding: "50px",
                ...theme.spo.imageView.imagePreview1,
              }}
            >
              Immagine attualmente non disponibile
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default ImageView;
