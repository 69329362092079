import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { SetInfoCart, SetTotalCart } from "../redux/actions";
import { GET, POST, DELETE, PUT } from "request-helper";

import Footer from "../components/Footer";
import LayoutWithHead from "../components/LayoutWithHead";
import DialogWithContent from "../components/DialogWithContent";
import SellerConditions from "../components/Conditions/SellerConditions";
import Logo from "../components/Logo";
import { roundTwoDigit } from "../utility/utility";
import DialogConfirm from "../components/DialogConfirm";
import ProductCounter from "../components/Product/ProductCounter";
import SummaryProductInCart from "../components/Product/SummaryProductInCart";
import DeliverySummary from "../components/Product/DeliverySummary";
import InfoTolleranceField from "../components/Product/InfoTolleranceField";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrashAlt,
  faInfoCircle,
  faChevronCircleLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { NotificationManager } from "react-notifications";
import Moment from "react-moment";

import { FormattedNumber } from "react-intl";

const useStyles = makeStyles((theme) => ({
  colGridDx: {
    paddingLeft: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  backgroundDiv: {
    backgroundColor: theme.spo.product.backgroundPaper,
  },
  backgroundPage: {
    backgroundColor: theme.spo.product.backgroundPage,
  },
  mainBodyGrid: {
    marginTop: theme.spo.mSm,
  },
}));

const CartSummary = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [deliveryDetail, setDeliveryDetail] = React.useState(null);

  const [openDialogExpiredCart, setOpenDialogExpiredCart] = React.useState(
    false
  );

  const [openAddFidelityCard, setOpenAddFidelityCard] = React.useState(false);
  const [codeTessera, setCodeTessera] = React.useState("");

  const [startFetchComplete, setStartFetchComplete] = React.useState(false);
  const labelConfirmText = "Conferma";
  const labelWaitConfirmText = "Attendi...";
  const [labelConfirm, setLabelConfirm] = React.useState(labelConfirmText);

  const [sellerCondition, setSellerCondition] = React.useState(false);
  const [sellerUnchecked, setSellerUnchecked] = React.useState(false);

  const [enableSimilar, setEnableSimilar] = React.useState(true);
  const [cartNote, setCartNote] = React.useState(null);
  const [cartSummary, setCartSummary] = React.useState(null);
  const [AmountIncr, setAmountIncr] = React.useState(null);
  const [popOverTolleranza, setPopOverTolleranza] = React.useState(false);
  const [anchorElInfo, setAnchorElInfo] = React.useState(null);
  const [
    dialogCompletePrenotation,
    setDialogCompletePrenotation,
  ] = React.useState(null);

  const [fidelityCode, setFidelityCode] = React.useState("");
  const [fidelityMessage, setFidelityMessage] = React.useState("");

  const [deliveryCost, setDeliveryCost] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(0);

  const [positionScroll, setPositioncroll] = React.useState(0);

  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    myFunction();
  };

  // Get the header

  // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function myFunction() {
    if (document.documentElement.clientWidth > 600)
      setPositioncroll(window.pageYOffset);
  }

  const removeAllFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveAllFromCart?shopId=" +
        props.store.CartReducers.shopId,
      null,
      null,
      true
    ).then((data) => {
      props.store.CartReducers.webSpoProductInCart = [];
      props.SetInfoCart(props.store.CartReducers);
      fetchCartSummary();
    });
  };

  const createDeliveryDetail = (data) => {
    var obj = {
      deliveryMode: data.idDeliveryModeNavigation.description,
      dateDelivery: data.webSpoTimeAllocation.dateAllocation,
      timeStart: data.webSpoTimeAllocation.webSpoTimeDefinition.timeStart,
      timeEnd: data.webSpoTimeAllocation.webSpoTimeDefinition.timeEnd,
      shopAddress:
        data.puntoVendita.indir +
        " " +
        data.puntoVendita.loca +
        " " +
        data.puntoVendita.prov,
      deliveryAddress: data.idAddressNavigation
        ? data.idAddressNavigation.address +
          " " +
          data.idAddressNavigation.city +
          " " +
          data.idAddressNavigation.postalCode +
          " " +
          data.idAddressNavigation.province
        : null,
      ShopInfo: data.puntoVendita.desPv,
    };
    setDeliveryDetail(obj);
  };

  const fetchCartSummary = () => {
    if (props.store.CartReducers == null) return;
    let param = {
      shopId: props.store.CartReducers.shopId,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetCartSummary",
      null,
      param,
      true
    ).then((data) => {
      if (data.expired || data.webSpoTimeAllocation.cartDelimited == 0) {
        setOpenDialogExpiredCart(true);
        return;
      }
      setCartSummary(data);
      setCodeTessera(data.fidelityCardCode);
      setFidelityCode(data.fidelityCardCode);
      createDeliveryDetail(data);
    });
  };

  const fetchShipValue = () => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/CalculateShipValue?shopId=" +
        props.store.CartReducers.shopId,
      null,
      null,
      true
    ).then((data) => {
      var res = cartSummary.webSpoProductInCart.filter(
        (c) => c.idProduct === "SP001"
      );
      res[0] = data;

      setCartSummary(cartSummary);
    });
  };

  const updateQuantityProduct = (product, quantity) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/UpdateQuantityToCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD +
        "&quantity=" +
        quantity,
      null,
      null,
      true
    ).then((data) => {
      fetchCartSummary();
      var res = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => c.idProduct === product.coD_RIORD
      );
      res[0].quantity = quantity;

      props.SetInfoCart(props.store.CartReducers);
      fetchShipValue();
    });
  };

  const removeFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveFromCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD,
      null,
      null,
      true
    ).then((data) => {
      fetchCartSummary();
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      props.store.CartReducers.webSpoProductInCart = props.store.CartReducers.webSpoProductInCart.filter(
        (c) => {
          return c.idProduct !== product.coD_RIORD;
        }
      );
      props.SetInfoCart(props.store.CartReducers);
      fetchShipValue();
    });
  };

  const fetchConfirmCart = () => {
    if (sellerCondition == false) {
      setStartFetchComplete(false);
      setSellerUnchecked(true);
      NotificationManager.error(
        "Per completare l'ordine devi accettare le condizioni di vendita"
      );
      return;
    }
    console.log(
      "REACT_APP_CONFIRM_DIRECT" + process.env.REACT_APP_CONFIRM_DIRECT
    );
    if (process.env.REACT_APP_CONFIRM_DIRECT == "false") {
      saveStateCartSummary();
      props.history.push(
        process.env.REACT_APP_BASE_PATH_ROUTE + "/PaymentMode"
      );
      return;
    }

    setLabelConfirm(labelWaitConfirmText);
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ConfirmCart?shopId=" +
        props.store.CartReducers.shopId +
        "&enableSimilar=" +
        enableSimilar +
        "&note=" +
        (cartNote != null && cartNote != undefined ? cartNote : ""),
      null,
      null,
      true
    ).then((data) => {
      setDialogCompletePrenotation(true);
      setStartFetchComplete(false);
      setLabelConfirm(labelConfirmText);
    });
  };

  const fetchAddFidelityCard = (fidelityCode) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO + "v1/User/AddFidelityCard",
      { fidelityCardCode: codeTessera || fidelityCode },
      null,
      true
    ).then((data) => {
      setOpenAddFidelityCard(false);
      if (data.Status == 500) {
        if (data.ExceptionDetail.Code == "AlreadyUsed") {
          setFidelityMessage(
            "Questa tessera è già associata ad un altro utente. Se è la tua tessera contatta l'assitenza."
          );
          // NotificationManager.error(
          //   "Questa tessera è già associata ad un altro utente. Se è la tua tessera contatta l'assitenza."
          // );
          setCodeTessera("");
          return;
        }
      }
      if (data.error) {
        setFidelityMessage(
          "Non è possibile associare questa tessera, verificare la correttezza dei dati inseriti"
        );
        // NotificationManager.error(
        //   "Non è possibile associare questa tessera, verificare la correttezza dei dati inseriti"
        // );
        setCodeTessera("");
        return;
      }
      setCodeTessera(fidelityCode);
      NotificationManager.success("Tessera associata correttamente");
    });
  };

  const saveStateCartSummary = () => {
    props.store.CartReducers.shopId = props.store.CartReducers.shopId;
    props.store.CartReducers.enableSimilar = enableSimilar;
    props.store.CartReducers.cartNote =
      cartNote != null && cartNote != undefined ? cartNote : "";
    props.SetInfoCart(props.store.CartReducers);
  };

  useEffect(() => {
    fetchCartSummary();
  }, []);

  useEffect(() => {
    if (startFetchComplete == true) {
      fetchConfirmCart();
    }
  }, [startFetchComplete]);

  useEffect(() => {
    if (cartSummary == null) return;
    if (cartSummary.webSpoProductInCart) {
      setAmountIncr(
        roundTwoDigit(
          cartSummary.webSpoProductInCart.reduce(
            (accumul, prod) => accumul + prod.adjustedAmount,
            0
          )
        )
      );
    }
  }, [cartSummary]);

  const cartPrenotationCompleted = () => {
    props.SetInfoCart(null);
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
  };

  return (
    <div>
      <LayoutWithHead
        rowLogoLeft={<Logo></Logo>}
        rowLogoRight={
          <Grid container>
            <DeliverySummary deliveryDetail={deliveryDetail}></DeliverySummary>
          </Grid>
        }
        rowBar={
          <>
            <Grid item xs={4}>
              {/* Tab menu */}

              <Tooltip title="Torna agli acquisti">
                <IconButton
                  style={{ fontSize: "45px", padding: 0 }}
                  color="primary"
                  onClick={() => {
                    props.history.push(
                      process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </IconButton>
              </Tooltip>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.history.push(
                    process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                  );
                }}
                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              >
                Torna agli acquisti
              </Button> */}
            </Grid>
            <Grid
              item
              xs={8}
              style={{ textAlign: "right", paddingRight: "15px" }}
            >
              {/* {cartSummary && (
                <h2 style={{ marginTop: 0, marginBottom: 0 }}>
                  Totale :{" "}
                  <FormattedNumber
                    value={
                      Math.round(
                        (cartSummary.webSpoProductInCart.reduce(
                          (accumul, prod) => accumul + (prod.amount || 0),
                          0
                        ) +
                          (AmountIncr || 0)) *
                          100
                      ) / 100
                    }
                    style="currency"
                    currency="EUR"
                    currencySign="accounting"
                  />
                </h2>
              )} */}
            </Grid>

            <Grid item xs={4} style={{ position: "relative" }}>
              {/* Tab menu */}
              {/* <div style={{ position: "absolute", right: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setStartFetchComplete(true);
                  }}
                >
                  {labelConfirm}
                </Button>
              </div> */}
            </Grid>
          </>
        }
      >
        <Grid
          xs={12}
          id="mainBox"
          style={{
            backgroundColor: theme.spo.product.backgroundPaper,
            // padding: theme.spo.pSm,
            //width: "100%",
          }}
        >
          <Grid container>
            {/* col sx */}
            <Grid item xs={12} sm={8} id="colGridSx">
              <Grid container id="containerColGridSx">
                <Grid item xs={12}>
                  <Paper style={{ padding: theme.spo.pMd }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <h3 style={{ marginTop: 0 }}>Elementi nel carrello</h3>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <DialogConfirm
                          title={"Elimina"}
                          text={"Sei sicuro di voler svuotare il carrello?"}
                        >
                          {(confirm) => (
                            <Tooltip title={"Svuota il carrello"}>
                              <IconButton
                                disabled={
                                  !props.store.CartReducers ||
                                  !props.store.CartReducers
                                    .webSpoProductInCart ||
                                  props.store.CartReducers.webSpoProductInCart
                                    .length == 0
                                }
                                aria-label="status"
                                onClick={confirm(() => {
                                  removeAllFromCart();
                                })}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </DialogConfirm>
                      </Grid>
                      <Grid xs={12}>
                        <SummaryProductInCart
                          showTotal={false}
                          cartSummary={cartSummary}
                          AmountIncr={AmountIncr}
                          removeFromCart={removeFromCart}
                          updateQuantityProduct={updateQuantityProduct}
                          totalSummary={(total) => {
                            props.SetTotalCart(total);
                          }}
                          deliveryCost={(deliveryCost) => {
                            setDeliveryCost(deliveryCost);
                          }}
                          subTotal={(subTotal) => {
                            setSubTotal(subTotal);
                          }}
                        ></SummaryProductInCart>
                      </Grid>
                      {cartSummary && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: theme.spo.mSm,
                          }}
                        >
                          <Box>
                            <h4
                              style={
                                {
                                  // marginTop: theme.spo.mSm,
                                  // marginBottom: theme.spo.mXs,
                                }
                              }
                            >
                              Note per il punto vendita:
                            </h4>
                            <TextField
                              style={{ width: "100%" }}
                              id="standard-multiline-static"
                              // label="Note"
                              multiline
                              rows={2}
                              value={cartNote}
                              onChange={(event) => {
                                setCartNote(event.target.value);
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* col dx */}
            <Grid
              item
              xs={12}
              sm={4}
              id="colGridDx"
              className={classes.colGridDx}
              style={{}}
            >
              <Grid container id="containerColGridSx">
                <Grid item xs={12}>
                  <Paper
                    style={{
                      padding: theme.spo.pMd,
                      marginTop:
                        positionScroll > 280 ? -(280 - positionScroll) : 0,
                    }}
                    id="paperSummary"
                  >
                    <h3 style={{ marginTop: 0 }}>Riepilogo Carrello</h3>
                    {/* sotto totale */}
                    <Grid
                      container
                      style={{
                        padding: "10px",
                        borderTop: "dotted 1px",
                      }}
                    >
                      <Grid item xs="6">
                        Articoli{" "}
                      </Grid>
                      <Grid item xs="6" style={{ textAlign: "right" }}>
                        {" "}
                        <FormattedNumber
                          value={subTotal}
                          style="currency"
                          currency="EUR"
                          currencySign="accounting"
                        />
                      </Grid>
                    </Grid>
                    {/* spese di consegna */}
                    <Grid
                      container
                      style={{
                        padding: "10px",
                        borderTop: "dotted 1px",
                      }}
                    >
                      <Grid item xs="6">
                        Spese di consegna{" "}
                      </Grid>
                      <Grid item xs="6" style={{ textAlign: "right" }}>
                        {" "}
                        <FormattedNumber
                          value={deliveryCost}
                          style="currency"
                          currency="EUR"
                          currencySign="accounting"
                        />
                      </Grid>
                    </Grid>
                    {/* tolleranza prodotti peso */}
                    <Grid
                      container
                      style={{
                        padding: "10px",
                        borderTop: "dotted 1px",
                      }}
                    >
                      <Grid item xs="6">
                        <InfoTolleranceField
                          // showPercIncr={showPercIncr}
                          percIncrementWeight={
                            props.store.AppReducers &&
                            props.store.AppReducers.SpoShopCfg &&
                            props.store.AppReducers.SpoShopCfg
                              .percIncrementWeight
                              ? props.store.AppReducers.SpoShopCfg
                                  .percIncrementWeight
                              : 0
                          }
                        ></InfoTolleranceField>
                      </Grid>
                      <Grid item xs="6" style={{ textAlign: "right" }}>
                        {" "}
                        <FormattedNumber
                          value={roundTwoDigit(AmountIncr).toFixed(2)}
                          style="currency"
                          currency="EUR"
                          currencySign="accounting"
                        />
                      </Grid>
                    </Grid>
                    {/* Spesa totale */}
                    <Grid
                      container
                      style={{
                        backgroundColor: "#3f51b5",
                        color: "#fff",
                        padding: "10px",
                      }}
                    >
                      <Grid item xs="6">
                        Totale:{" "}
                      </Grid>
                      {cartSummary && cartSummary.webSpoProductInCart && (
                        <Grid item xs="6" style={{ textAlign: "right" }}>
                          <FormattedNumber
                            value={props.store.CartReducers.total}
                            style="currency"
                            currency="EUR"
                            currencySign="accounting"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Box style={{ marginTop: theme.spo.mSm }}>
                      <Tooltip
                        arrow
                        title={
                          <div style={{ fontSize: "14px;" }}>
                            <Typography>
                              Associa la tua tessera per ottenere i vantaggi e
                              le promozioni offerte del tuo punto vendita.
                            </Typography>{" "}
                          </div>
                        }
                      >
                        <IconButton disabled={false}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </IconButton>
                      </Tooltip>

                      <span style={{ marginTop: 0 }}>
                        {!codeTessera ? (
                          <span>Hai una tessera fidelity?</span>
                        ) : (
                          <span>Tessera a te associata</span>
                        )}
                      </span>
                    </Box>

                    <TextField
                      disabled={codeTessera}
                      error={fidelityMessage}
                      helperText={fidelityMessage}
                      value={fidelityCode}
                      style={{ width: "100%", marginTop: theme.spo.mXs }}
                      id="standard-required"
                      label="Codice fidelity"
                      onChange={(e) => {
                        setFidelityCode(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={codeTessera}
                              onClick={() => {
                                setFidelityMessage("");
                                setFidelityCode("");
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                            <Button
                              disabled={codeTessera}
                              // variant="contained"
                              // color="primary"
                              onClick={() => {
                                fetchAddFidelityCard(fidelityCode);
                              }}
                            >
                              Associa
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box style={{ marginTop: theme.spo.mSm }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enableSimilar}
                            onChange={(event) => {
                              setEnableSimilar(event.target.checked);
                            }}
                            name={"enableSimilar"}
                          />
                        }
                        label={
                          "In mancanza di disponibilità dei prodotti, permetti la sostituzione dei prodotti ordinati con prodotti similari a parità di prezzo"
                        }
                      />
                    </Box>
                    <Box>
                      <SellerConditions
                        checked={sellerCondition}
                        setAccepted={(val) => {
                          setSellerCondition(val);
                        }}
                        errorUnchecked={sellerUnchecked}
                      ></SellerConditions>
                    </Box>

                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: theme.spo.mSm,
                      }}
                    >
                      <Grid item xs={12} style={{ marginTop: theme.spo.mSm }}>
                        <Box display="flex" flexDirection="row-reverse">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setStartFetchComplete(true);
                            }}
                          >
                            {labelConfirm}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => {
            setDialogCompletePrenotation(false);
          }}
          aria-labelledby="simple-dialog-title"
          open={dialogCompletePrenotation}
        >
          <DialogTitle id="simple-dialog-title">
            La tua prenotazione è andata a buon fine
          </DialogTitle>
          <DialogContent>
            <div>
              <div>
                ti verrà inviata una mail di riepilogo dell'ordine. Ti verrà
                inoltre notificato via mail la presa in carico da parte del
                punto vendita.
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogCompletePrenotation(false);
                cartPrenotationCompleted();
              }}
              color="primary"
            >
              Continua
            </Button>
          </DialogActions>
        </Dialog>
        <DialogWithContent
          open={openDialogExpiredCart}
          onClose={() => {
            setOpenDialogExpiredCart(false);
          }}
          contentText={
            "Il tuo carrello è scaduto verrai rimandato alla sezione per la selezione di una nuova fascia oraria"
          }
          title="Carrello scaduto"
          action={[
            {
              onClick: () => {
                props.history.push(
                  process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart"
                );
              },
              label: "continua",
            },
          ]}
        ></DialogWithContent>
      </LayoutWithHead>
      <Footer></Footer>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, {
  SetInfoCart,
  SetTotalCart,
})(CartSummary);
