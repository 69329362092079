import { SET_SPO_CFG, SET_SPO_CFG_SHOP, SAVE_STATE_APP } from "../actionTypes";

const initialState = {};

const AppReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPO_CFG: {
      state = { ...state, ...{ SpoCfg: action.posts } };
      return state;
    }
    case SET_SPO_CFG_SHOP: {
      state = { ...state, ...{ SpoShopCfg: action.posts } };
      return state;
    }
    case SAVE_STATE_APP: {
      let obj = {};
      obj[action.payload.page] = action.payload.state;
      let stateSaved = { ...state.stateSaved, ...obj };
      state = { ...state, ...{ stateSaved: stateSaved } };
      return state;
    }
    default: {
      return state;
    }
  }
};
export default AppReducers;
