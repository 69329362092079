export default {
  theme: {
    overrides: {
      MuiIconButton: {
        root: {
          borderRadius: 0,
        },
        label: {
          justifyContent: "flex-start",
          "& p": {
            marginLeft: "10px",
            marginTop: "20px",
          },
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: "inherit",
          width: "100%",
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          backgroundColor: "transparent",
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#32aa48",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "#32aa48",
          color: "#fff",
        },
        iconButton: {
          backgroundColor: "#32aa48",
          "&:hover": {
            backgroundColor: "#339044",
          },
        },
        dayLabel: {
          margin: "5px 5px",
          color: "#000",
          width: "100%",
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          height: "300px",
        },
        week: {
          "& div[role=presentation]": {
            width: "100%",
          },
        },
      },

      MuiPickersDay: {
        day: {
          color: "#000",
          backgroundColor: "#ffffffbd",
          height: "40px",
          margin: "5px 5px",
          width: "95%",
          "&:hover": {
            backgroundColor: "#339044",
          },
        },
        daySelected: {
          backgroundColor: "#32aa48",
          "&:hover": {
            backgroundColor: "#339044",
          },
        },
        dayDisabled: {
          color: "#ccc",
        },
        current: {
          color: "#32aa48",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#32aa48",
        },
      },
    },

    spo: {
      mXs: 5,
      mSm: 15,
      mMd: 30,
      mLg: 40,
      pXs: 5,
      pSm: 15,
      pMd: 30,
      pLg: 40,
      darkGray: {
        color: "#6e6d6e",
      },
      toolBar: {
        backgroundColor: "#fff",
        //height: "25px",
        minHeight: "25px",
        maxHeight: "40px",
        color: "#6e6d6e",
        borderBottom: "solid 1px #ccc",
        boxShadow: "1px 1px 13px 6px #ccc",
      },
      footer: {
        backgroundColor: "#fffdfb",
        minHeight: "200px",
        // color: "#cccccc",
      },
      footerCompanyData: {
        backgroundColor: "#eee",
      },
      imageView: {
        containerImage: {
          backgroundColor: "#fff",
          borderRadius: "10px",
          margin: "0 15px",
          padding: "15px",
          marginBottom: "15px",
          height: "300px",
          textAlign: "center",
        },
        imagePreview1: {},
      },
      spoLayoutBg: {
        logoContainer: {
          //backgroundColor: "#fff",
          position: "absolute",
          right: 0,
          padding: "15px",
          marginTop: "-45px",
          marginRight: "-15px",
        },
      },
      wizardCart: {
        titleDeliveryMode: {
          color: "#32aa48",
          marginTop: "20px",
          marginBottom: "10px",
          fontWeight: 500,
          fontSize: "32px",
          letterSpacing: "-1px",
        },
        subTitleDeliveryMode: {
          marginTop: 0,
          fontWeight: "400",
        },
        chipSelected: { backgroundColor: "#4caf50" },
        chipUnselected: {},
        chipUnavailable: { backgroundColor: "#ff0000", color: "#d6c8c8" },
        selectDate: {},
      },
      product: {
        mainHeader: {
          backgroundColor: "#32aa48",
        },
        textBoxSearch: {
          backgroundColor: "#fff",
          borderRadius: "50px",
          marginBottom: "5px",
        },
        headerListProduct: {
          backgroundColor: "#fff",
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
        },
        deliverySummary: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "12px",
          display: "inline",
          color: "#ffffff",
        },
        IconDeparment: {
          fontSize: "24px",
          color: "#6e6d6e",
        },
        IconDeparmentSelected: {
          fontSize: "24px",
          color: "#ffffff",
        },
        iconHeadTrash: {
          color: "#fff",
        },
        iconHeadCart: { color: "#fff" },
        colorFirstRowHead: "#ffffff",
        backgroundPaper: "#ffffff",
        backgroundPage: "#EFEEF0",
        breadcrumbBar: {
          padding: "5px",
          backgroundColor: "#32aa48",
          borderRadius: "5px",
          color: "#fff",
          marginTop: "5px",
          marginBottom: "10px",
        },
        sizeIconDisplayProduct: "28px",
        backgroundColorIconPrimary: "#dc2c34",
        departmentSelected: {
          width: "7%",
          height: "72px",
          fontSize: "10px",
          backgroundColor: "#dc2c34",
          borderColor: "#0000ff",
          fontWeight: "bold",
          color: "#ffffff",
          border: "none",
        },
        departmentUnSelected: {
          width: "7%",
          height: "72px",
          fontSize: "10px",
          border: "none",
        },
        card: {
          sizeCategoryText: 10,
          sizeDescriptionText: 16,
          sizeBrandText: 12,
          sizePriceText: "20px",
          backGround: "#fff",
          colorCategory: "#000000",
          colorDescription: "#4d4d4d",
          colorBrand: "#000000",
          colorPrice: "#4d4d4d",
        },
        departmentPopLeft: {
          backgroundColor: "#4caf50",
          textAlign: "center",
          color: "#fff",
        },
      },
    },
  },
};
