import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Tab,
  Paper,
  List,
  ListItem,
  Tooltip,
  IconButton,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Drawer,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  hideExpandBarMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarXs: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },

  widthLeft: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
    },
  },

  widthLeftCollapsed: {
    width: "50px",
  },

  widthRightFull: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 50px)",
    },
    width: "calc(100% - 250px)",
  },
  widthRight: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "calc(100% - 50px)",
  },

  drawerWidth: {
    width: 250,
  },
}));

const BodyWithLeftBar = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const props_contentLeft = props.contentLeft;
  const props_contentRight = props.contentRight;

  const [showLeft, setShowLeft] = React.useState(true);
  const [collapsedLeft, setCollapsedLeft] = React.useState(false);
  const [drawer, setDrawer] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  return (
    <Grid
      container
      style={{
        marginTop: theme.spo.mSm,
      }}
    >
      <div
        className={
          showLeft == false
            ? classes.widthLeftCollapsed
            : !collapsedLeft
            ? classes.widthLeft
            : classes.widthLeftCollapsed
        }
        style={{
          // marginRight: theme.spo.mSm,
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Grid item xs={12}>
          <Paper
            style={{
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            <Box className={classes.hideExpandBarMd}>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => {
                  setCollapsedLeft(!collapsedLeft);
                  setShowLeft(true);
                }}
              >
                <FontAwesomeIcon icon={faArrowsAltH} />
                {/* md */}
              </IconButton>
            </Box>
            <Box className={classes.hideExpandBarXs}>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={toggleDrawer("left", true)}
              >
                <FontAwesomeIcon icon={faArrowsAltH} />
                {/* xs */}
              </IconButton>
            </Box>
            <Drawer
              anchor={"left"}
              open={drawer["left"]}
              onClose={toggleDrawer("left", false)}
            >
              <div
                className={classes.drawerWidth}
                role="presentation"
                // onClick={toggleDrawer("left", false)}
                onKeyDown={toggleDrawer("left", false)}
              >
                {props_contentLeft}
              </div>
            </Drawer>
            {showLeft == true && (
              <Slide
                direction="right"
                in={!collapsedLeft}
                mountOnEnter
                unmountOnExit
                // timeout={{ appear: 5000, enter: 100, exit: 100 }}
              >
                <div className={classes.hideExpandBarMd}>
                  <Box style={{ padding: theme.spo.pSm }}>
                    {props_contentLeft}
                  </Box>
                </div>
              </Slide>
            )}
          </Paper>
        </Grid>
      </div>

      <Grid
        container
        className={
          showLeft == false
            ? classes.widthRight
            : !collapsedLeft
            ? classes.widthRightFull
            : classes.widthRight
        }
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: theme.spo.product.backgroundPage,
          }}
        >
          {props_contentRight}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BodyWithLeftBar;
