export const parseAddressComponents = (addressComponents, type, short) => {
  let filtered = addressComponents.filter((c) => {
    return c.types[0] == type;
  });
  if (filtered[0]) {
    if (short == true) return filtered[0].short_name;
    else return filtered[0].long_name;
  }
  return "";
};

export const geocodeAddress = (fullAddress, callback) => {
  //https://maps.googleapis.com/maps/api/geocode/json?address=via+cavour+34+marche+ancona&key=
  fetch(
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      fullAddress +
      "&key=" +
      process.env.REACT_APP_GOOGLE_API_KEY
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      callback(data.results);
    });
};
