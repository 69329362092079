import {
  validateEmail,
  validateNumber,
  validateNotNumber,
  validateTaxCode,
} from "../utility/utility";

const validationInvoice = (haveInvoice, userData) => {
  return {
    invoiceBusinessName: {
      error: haveInvoice && !userData.invoiceBusinessName,
      required: true,
    },

    invoiceVATNumber: {
      //   error: haveInvoice && !(userData.invoiceVATNumber || userData.fiscalCode),
      error: function () {
        if (haveInvoice) {
          if (!(userData.invoiceVATNumber || userData.fiscalCode)) return true;
          if (userData.invoiceVATPrefix == "IT") {
            if (!validateNumber(userData.invoiceVATNumber)) {
              return "Partita iva non valida";
            }
            if (userData.invoiceVATNumber.length != 11) {
              return "Partita iva non valida";
            }
          }
        }
      },
      required: !(userData.invoiceVATNumber || userData.fiscalCode),
    },
    fiscalCode: {
      error: function () {
        if (haveInvoice) {
          if (!(userData.invoiceVATNumber || userData.fiscalCode)) return true;
          if (userData.fiscalCode && !validateTaxCode(userData.fiscalCode)) {
            return "Codice fiscale non valido";
          }
        }
      },
      required: !(userData.invoiceVATNumber || userData.fiscalCode),
    },
    invoiceAddress: {
      error: haveInvoice && !userData.invoiceAddress,
      required: true,
    },
    invoiceCity: {
      error: function () {
        if (haveInvoice) {
          if (!userData.invoiceCity) return true;
          if (
            userData.invoiceCity &&
            !validateNotNumber(userData.invoiceCity)
          ) {
            return "Non sono ammessi numeri";
          }
        }
      },
      required: true,
    },
    invoiceZipCode: {
      error: function () {
        if (haveInvoice) {
          if (!userData.invoiceZipCode) return true;
          if (!validateNumber(userData.invoiceZipCode)) {
            return "Formato non valido";
          } else return null;
        }
      },
      required: true,
    },
    invoiceProvince: {
      error: function () {
        if (haveInvoice) {
          if (!userData.invoiceProvince) return true;
          if (!validateNotNumber(userData.invoiceProvince)) {
            return "Non sono ammessi numeri";
          }
        }
      },
      required: true,
    },
    invoiceVATPrefix: {
      error: function () {
        if (haveInvoice) {
          if (!userData.invoiceVATPrefix) return true;
          if (!validateNotNumber(userData.invoiceVATPrefix)) {
            return "Non sono ammessi numeri";
          }
        }
      },
      required: true,
    },
    invoicePECMailAddress: {
      error: function () {
        if (haveInvoice) {
          if (!(userData.invoicePECMailAddress || userData.invoiceSDICode))
            return true;
          if (
            userData.invoicePECMailAddress &&
            !validateEmail(userData.invoicePECMailAddress)
          ) {
            return "Formato email non valido";
          }
        }
      },
      required: !(userData.invoicePECMailAddress || userData.invoiceSDICode),
    },
    invoiceSDICode: {
      error: function () {
        if (haveInvoice) {
          if (!(userData.invoicePECMailAddress || userData.invoiceSDICode))
            return true;
          if (userData.invoiceSDICode && userData.invoiceSDICode.length != 7)
            return "Lunghezza del codice non valida";
        }
      },
      required: !(userData.invoicePECMailAddress || userData.invoiceSDICode),
    },
  };
};

export default validationInvoice;
