import React, { useState, useEffect, useContext } from "react";

import StepperMUI from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spo.mSm,
    marginBottom: theme.spo.mSm,
  },
}));

const Stepper = (props) => {
  /*
    [
        {
        title: 
        content:
        showBack:
        optional:
        onNext:()=>{}
        }
    ]
    */
  const props_setSkipped = props.setSkipped;
  const props_items = props.items;
  const props_onFinish = props.onFinish;

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const [skipped, setSkipped] = React.useState(new Set());
  function getSteps() {
    return props_items.map((c) => c.title);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      //ultimo step
      if (activeStep === steps.length - 1 && props_onFinish) {
      }
      if (props_items[prevActiveStep].onNext) {
        props_items[prevActiveStep].onNext();
      }
      return prevActiveStep + 1;
    });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      props_setSkipped(newSkipped);
      return newSkipped;
    });
  };

  function getStepContent(step) {
    return props_items[step].content;
  }
  const steps = getSteps();

  return (
    <>
      <StepperMUI activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </StepperMUI>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div className={classes.content}>Operazione completata</div>
          </div>
        ) : (
          <div>
            <div className={classes.content}>{getStepContent(activeStep)}</div>
            <div>
              {props_items[activeStep].showBack && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              )}
              {props_items[activeStep].optional && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Fine" : "Avanti"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Stepper;
