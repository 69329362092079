import {
  SET_SALES_MASTER,
  SET_SALES_MASTER_RANGE,
  SET_SALES_MASTER_RANGE_AP
} from "../actionTypes";

const initialState = [];

const SalesReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SALES_MASTER: {
      if (!action.posts) return state;
      if (action.posts.error) {
        return state;
      }
      if (action.posts) state = [...(action.posts || [])];
      return state;
    }
    case SET_SALES_MASTER_RANGE: {
      if (!action.posts) return state;
      if (action.posts.error) {
        console.error("errore connessione server");
        return state;
      }
      if (action.posts) state = [...(action.posts || [])];
      return state;
    }
    case SET_SALES_MASTER_RANGE_AP: {
      if (!action.posts) return state;
      if (action.posts.error) {
        console.error("errore server");
        return state;
      }
      if (action.posts) state = [...(action.posts || [])];
      return state;
    }
    default: {
      return state;
    }
  }
};
export default SalesReducers;
