import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Box,
  Tabs,
  Tab,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import { SetInfoCart } from "../redux/actions";
import LayoutWithHead from "../components/LayoutWithHead";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import DialogConfirm from "../components/DialogConfirm";
import { roundTwoDigit } from "../utility/utility";

import { GET, PUT, POST } from "request-helper";

const useStyles = makeStyles((theme) => ({}));

const PaymentMode = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [amount, setAmount] = React.useState(0);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponMessage, setCouponMessage] = React.useState("");
  const [couponObject, setCouponObject] = React.useState(null);
  const [typePayment, setTypePayment] = React.useState("1");

  const [modePayment, setModePayment] = React.useState(0);

  const [dialogCompletePrenotation, setDialogCompletePrenotation] =
    React.useState(null);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChangeIndex = (event, newValue) => {
    setModePayment(newValue);
  };

  const handleChangeRitiro = (event) => {
    setTypePayment(event.target.value);
  };

  //0 consegna
  //1 elettronico

  //0 contanti
  //1 cc/bancomat

  const fetchConfirmCart = (formatPayment) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ConfirmCart?shopId=" +
        props.store.CartReducers.infoCart.shopId +
        "&enableSimilar=" +
        props.store.CartReducers.infoCart.enableSimilar +
        "&note=" +
        props.store.CartReducers.infoCart.cartNote,
      null,
      null,
      true
    ).then((data) => {
      if (formatPayment != 3) setDialogCompletePrenotation(true);
    });
  };

  const fetchTypePayment = (formatPayment) => {
    return PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Payment/SetPaymentTypeToCart?shopId=" +
        props.store.CartReducers.infoCart.shopId +
        "&idPaymentType=" +
        formatPayment +
        "&couponCode=" +
        couponCode,
      null,
      null,
      true
    );
  };

  const fetchMakePaymentOnline = (formatPayment) => {
    return POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Payment/Create",
      {
        shopId: props.store.CartReducers.infoCart.shopId,
        urlOk:
          window.location.origin +
          process.env.REACT_APP_BASE_PATH_ROUTE +
          "/PaymentOk",
        urlKo:
          window.location.origin +
          process.env.REACT_APP_BASE_PATH_ROUTE +
          "/PaymentKo",
      },
      null,
      true
    ).then((data) => {
      if (data.redirectLightBox) {
        window.location.href = data.redirectLightBox;
      }
    });
  };

  const fetchCouponVerify = () => {
    if (!couponCode) return;
    if (props.store.CartReducers == null) return;
    //string code, string shopId, decimal amount
    let param = {
      code: couponCode,
      shopId: props.store.CartReducers.shopId,
      amount: amount,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Payment/VerifyCoupon",
      null,
      param,
      true
    ).then((data) => {
      if (!data.idCoupon) {
        setCouponMessage("Coupon non valido");
        setCouponObject(null);
        return;
      }
      setCouponMessage("");
      setCouponObject(data);
    });
  };

  useEffect(() => {
    if (!props.store.CartReducers) return;
    setAmount(props.store.CartReducers.total);
  }, [props.store.CartReducers]);

  useEffect(() => {
    if (couponObject == null) return;
  }, [couponObject]);

  const handleClickProcedi = () => {
    //pagamento consegna
    if (process.env.REACT_APP_PAYMENT_DIRECT_CC !== "true") {
      fetchTypePayment(typePayment).then((data) => {
        if (data.error) return;
        fetchConfirmCart();
      });
      return;
    }
    switch (modePayment) {
      case 0:
        fetchTypePayment(3).then((data) => {
          if (data.error) return;
          fetchMakePaymentOnline();
          fetchConfirmCart(3);
        });
        break;
      case 1:
        fetchTypePayment(typePayment).then((data) => {
          if (data.error) return;
          fetchConfirmCart();
        });
        break;
    }
  };

  const cartPrenotationCompleted = () => {
    props.SetInfoCart(null);
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
  };

  return (
    <div>
      <LayoutWithHead
        rowLogoLeft={<Logo></Logo>}
        rowBar={
          <>
            <Grid item xs={6}>
              {/* Tab menu */}
              <Tooltip title="Torna agli acquisti">
                <IconButton
                  style={{ fontSize: "45px", padding: 0 }}
                  color="primary"
                  onClick={() => {
                    props.history.push(
                      process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        }
      >
        <Box
          style={{
            backgroundColor: theme.spo.product.backgroundPaper,
            padding: theme.spo.pSm,
          }}
        >
          <Grid item xs={12} style={{}}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <Box>
                        <h3 style={{ marginTop: 0 }}>CODICE SCONTO </h3>
                        <h4 style={{ marginTop: 0 }}>Hai un codice sconto?</h4>
                      </Box>

                      <TextField
                        error={couponMessage}
                        helperText={couponMessage}
                        value={couponCode}
                        style={{ width: "100%" }}
                        id="standard-required"
                        label="Codice coupon"
                        onChange={(e) => {
                          setCouponCode(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setCouponMessage("");
                                  setCouponObject(null);
                                  setCouponCode("");
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </IconButton>
                              <Button
                                // variant="contained"
                                // color="primary"
                                onClick={() => {
                                  fetchCouponVerify();
                                }}
                              >
                                Usa Coupon
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3 style={{ marginTop: 0 }}>MODALITÀ PAGAMENTO </h3>
                          <h4 style={{ marginTop: 0 }}>
                            Seleziona la modalità di pagamento
                          </h4>
                          <Tabs
                            value={modePayment}
                            onChange={handleChangeIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            {process.env.REACT_APP_PAYMENT_DIRECT_CC ==
                              "true" && (
                              <Tab label="Online" {...a11yProps(0)} />
                            )}

                            <Tab
                              label="Al ritiro"
                              {...(process.env.REACT_APP_PAYMENT_DIRECT_CC ==
                              "true"
                                ? a11yProps(1)
                                : a11yProps(0))}
                            />
                          </Tabs>
                          {process.env.REACT_APP_PAYMENT_DIRECT_CC ==
                            "true" && (
                            <TabPanel value={modePayment} index={0}>
                              <FontAwesomeIcon
                                icon={["fab", "cc-visa"]}
                                style={{
                                  fontSize: 36,
                                  paddingRight: theme.spo.pXs,
                                }}
                              />
                              <FontAwesomeIcon
                                icon={["fab", "cc-mastercard"]}
                                style={{
                                  fontSize: 36,
                                  paddingRight: theme.spo.pXs,
                                }}
                              />
                              <FontAwesomeIcon
                                icon={["fab", "cc-amex"]}
                                style={{
                                  fontSize: 36,
                                  paddingRight: theme.spo.pXs,
                                }}
                              />
                            </TabPanel>
                          )}
                          <TabPanel
                            value={modePayment}
                            index={
                              process.env.REACT_APP_PAYMENT_DIRECT_CC == "true"
                                ? 1
                                : 0
                            }
                          >
                            <FormControl component="fieldset">
                              <RadioGroup
                                name="typePayment"
                                value={typePayment}
                                onChange={handleChangeRitiro}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Contanti"
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label="Carta di credito o bancomat"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TabPanel>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <h3 style={{ marginTop: 0 }}>Riepilogo ordine</h3>
                      <Grid container>
                        <Grid
                          container
                          style={{
                            padding: "10px",
                            borderTop: "dotted 1px",
                          }}
                        >
                          <Grid item xs="6">
                            Carrello{" "}
                          </Grid>
                          <Grid item xs="6" style={{ textAlign: "right" }}>
                            {" "}
                            <FormattedNumber
                              value={roundTwoDigit(amount || 0)}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />
                          </Grid>
                        </Grid>
                        {couponObject && (
                          <Grid
                            container
                            style={{
                              padding: "10px",
                              borderTop: "dotted 1px",
                            }}
                          >
                            <Grid item xs="6">
                              Sconto coupon:
                            </Grid>
                            <Grid item xs="6" style={{ textAlign: "right" }}>
                              <FormattedNumber
                                value={roundTwoDigit(
                                  couponObject.amountDiscount
                                )}
                                style="currency"
                                currency="EUR"
                                currencySign="accounting"
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          style={{
                            backgroundColor: "#3f51b5",
                            color: "#fff",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs="6">
                            Totale:{" "}
                          </Grid>
                          <Grid item xs="6" style={{ textAlign: "right" }}>
                            <FormattedNumber
                              value={roundTwoDigit(
                                amount -
                                  (couponObject
                                    ? couponObject.amountDiscount
                                    : 0)
                              )}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />
                          </Grid>
                        </Grid>
                        <div style={{ marginTop: theme.spo.mSm }}>
                          L'importo dell'autorizzazione (blocco del plafond o
                          disponibilità della carta) verrà ripristinato dal
                          circuito che ha emesso la sua carta, con tempistiche
                          variabili a seconda del singolo circuito. Solo
                          l'effettivo importo della spesa verrà addebitato.
                        </div>
                        <DialogConfirm
                          title={"Sei sicuro di voler completare l'ordine?"}
                          text={
                            <>
                              <h3>Totale da pagare:</h3>
                              <FormattedNumber
                                value={roundTwoDigit(
                                  amount -
                                    (couponObject
                                      ? couponObject.amountDiscount
                                      : 0)
                                )}
                                style="currency"
                                currency="EUR"
                                currencySign="accounting"
                              />
                              <h3>Modalità pagamento selezionata :</h3>
                              {process.env.REACT_APP_PAYMENT_DIRECT_CC ==
                                "true" && (
                                <div>
                                  {modePayment == 1 && typePayment == 1 && (
                                    <div>
                                      Pagamento alla consegna (contanti)
                                    </div>
                                  )}
                                  {modePayment == 1 && typePayment == 2 && (
                                    <div>
                                      Pagamento alla consegna (carta di credito
                                      o bancomat)
                                    </div>
                                  )}
                                  {modePayment == 0 && (
                                    <div>Pagamento online</div>
                                  )}
                                </div>
                              )}
                              {process.env.REACT_APP_PAYMENT_DIRECT_CC !==
                                "true" && (
                                <div>
                                  {typePayment == 1 && (
                                    <div>
                                      Pagamento alla consegna (contanti)
                                    </div>
                                  )}
                                  {typePayment == 2 && (
                                    <div>
                                      Pagamento alla consegna (carta di credito
                                      o bancomat)
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          }
                        >
                          {(confirm) => (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "100%",
                                marginTop: theme.spo.mSm,
                              }}
                              onClick={confirm(() => {
                                handleClickProcedi();
                              })}
                            >
                              Procedi al pagamento
                            </Button>
                          )}
                        </DialogConfirm>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LayoutWithHead>
      <Footer></Footer>
      <Dialog
        onClose={() => {
          setDialogCompletePrenotation(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={dialogCompletePrenotation}
      >
        <DialogTitle id="simple-dialog-title">
          La tua prenotazione è andata a buon fine
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              Ti verrà inviata una mail di riepilogo dell'ordine. Ti verrà
              inoltre notificato via mail la presa in carico da parte del punto
              vendita.
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="cartPaymentCompletedContinue"
            onClick={() => {
              setDialogCompletePrenotation(false);
              cartPrenotationCompleted();
            }}
            color="primary"
          >
            Continua
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, { SetInfoCart })(PaymentMode);
