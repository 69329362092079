// These must be the first lines in src/index.js
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import "./index.css";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
// const rootElement = document.getElementById("root");
const baseUrl = "";
ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
