/**
 * Contiene le informazioni generali del layout
 */
import { SET_LAYOUT_STATUS_LEFT_BAR } from "../../commonActionTypes";

const initialState = {
  statusLeftBar: true,
  itemsOnPaginationFilter: 20
};

const LayoutReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_STATUS_LEFT_BAR: {
      state = { ...state, ...{ statusLeftBar: action.payload } };
      return state;
    }

    default: {
      return state;
    }
  }
};
export default LayoutReducers;
