import React, { useState, useEffect, useContext } from "react";
import { UserManager } from "oidc-client";
import { Redirect } from "react-router";

export default function Callback(props) {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    var mgr = new UserManager({ response_mode: "query" })
      .signinRedirectCallback()
      .then(function(user) {
        props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/login");
      });
  }, []);

  return null;
}
