import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { roundTwoDigit } from "../../utility/utility";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCartPlus } from "@fortawesome/free-solid-svg-icons";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { FormattedNumber } from "react-intl";
import ProductCounter from "./ProductCounter";

const GridProduct = (props) => {
  const theme = useTheme();
  const props_product = props.product;
  const props_cart = props.cart || {};

  const props_readOnly = props.readOnly || false;
  const props_noCounter =
    props.noCounter == null || props.noCounter == undefined
      ? false
      : props.noCounter;
  const props_noFavorite =
    props.noFavorite == null || props.noFavorite == undefined
      ? false
      : props.noFavorite;
  const props_overridePrice = props.overridePrice;
  const props_newProduct = props.newProduct || function () {};
  const props_removeProduct = props.removeProduct || function () {};
  const props_updateQuantity = props.updateQuantity || function () {};
  const props_setFavorite = props.setFavorite || function () {};
  const props_favorites = props.favorites;

  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (!props_product) return;
    if (
      props_cart.webSpoProductInCart &&
      props_cart.webSpoProductInCart.filter
    ) {
      let objInCart = props_cart.webSpoProductInCart.filter((c) => {
        return c.idProduct == props_product.coD_RIORD;
      });
      if (objInCart && objInCart[0]) {
        setCount(objInCart[0].quantity);
      } else {
        setCount(0);
      }
      return;
    }
    setCount(0);
  }, [props_product]);

  const handleClickFavorite = () => {
    props_setFavorite(props_product);
  };
  const isFavoriteLocal = (product) => {
    if (!props_favorites) return false;
    let isfav = props_favorites.filter((c) => c.idProduct == product.coD_RIORD);
    return isfav[0] != undefined;
  };

  return (
    <Grid item xs={12} style={{ margin: theme.spo.mSm }}>
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: theme.spo.mSm, height: "70px" }}>
            <Grid container>
              {/* immagine */}
              <Grid item xs={1}>
                <div>
                  {props_product.immagine ? (
                    <img
                      style={{ height: "65px" }}
                      src={"data:image/png;base64," + props_product.immagine}
                    ></img>
                  ) : (
                    <div
                      style={{
                        height: "65px",
                        textAlign: "center",
                      }}
                    >
                      Immagine attualmente non disponibile
                    </div>
                  )}
                </div>
              </Grid>
              {/* Preferiti */}
              <Grid item xs={1}>
                <div style={{ textAlign: "center" }}>
                  {!props_noFavorite && (
                    <IconButton
                      color="primary"
                      aria-label="Aggiungi ai preferiti"
                      onClick={handleClickFavorite}
                      // style={{ padding: 0 }}
                    >
                      {isFavoriteLocal(props_product) ? (
                        //|| props_product.isFavorite
                        <FavoriteIcon></FavoriteIcon>
                      ) : (
                        <FavoriteBorderIcon></FavoriteBorderIcon>
                      )}
                    </IconButton>
                  )}
                </div>
              </Grid>
              {/* categoria/Descrizione /marca */}
              <Grid item xs={4} md={3}>
                <div
                  style={{
                    fontSize: theme.spo.product.card.sizeCategoryText,
                    color: theme.spo.product.card.colorCategory,
                  }}
                >
                  {props_product.category}
                </div>
                <div
                  style={{
                    fontSize: theme.spo.product.card.sizeDescriptionText,
                    color: theme.spo.product.card.colorDescription,
                    fontWeight: "bold",
                  }}
                >
                  <Link
                    href="#"
                    onClick={() => {
                      props.history.push(
                        process.env.REACT_APP_BASE_PATH_ROUTE +
                          "/detail/" +
                          props_product.coD_RIORD
                      );
                    }}
                  >
                    {props_product.description}
                  </Link>
                </div>
                <div
                  item
                  xs={12}
                  style={{
                    fontSize: theme.spo.product.card.sizeBrandText,
                    color: theme.spo.product.card.colorBrand,
                  }}
                >
                  {props_product.marca}
                </div>
              </Grid>
              {/* price */}
              <Grid item xs={2} md={2}>
                {props_product.promoPrice > 0 ? (
                  <div
                    style={{
                      textDecoration: "line-through",
                      color: "#ff0000",
                    }}
                  >
                    <FormattedNumber
                      value={roundTwoDigit(
                        props_overridePrice
                          ? props_overridePrice
                          : props_product.price
                      ).toFixed(2)}
                      style="currency"
                      currency="EUR"
                      currencySign="accounting"
                    />
                  </div>
                ) : (
                  <div>&nbsp; </div>
                )}
                {/*prChiaroPeso se 0 non viene visualizzato i lprezzo chiaro */}
                <Box style={{ fontSize: "12px", height: "14px" }}>
                  {props_product.prChiaroPeso != 0 && (
                    <div>
                      {/*se PrChiaroPeso != 0 
	                      2decimal arrotondamento
	                      Streing(Math.round(Price/PrChiaroPeso,2)) +  " "+ PrChiaroDes (string) */}
                      <FormattedNumber
                        value={roundTwoDigit(
                          (props_product.promoPrice || props_product.price) /
                            props_product.prChiaroPeso
                        )}
                        style="currency"
                        currency="EUR"
                        currencySign="accounting"
                      />{" "}
                      {props_product.prChiaroDes}
                    </div>
                  )}
                </Box>
                <div
                  style={{
                    fontSize: theme.spo.product.card.sizePriceText,
                    color: theme.spo.product.card.colorPrice,
                    fontWeight: "bold",
                  }}
                >
                  <FormattedNumber
                    value={roundTwoDigit(
                      props_product.promoPrice || props_product.price
                    ).toFixed(2)}
                    style="currency"
                    currency="EUR"
                    currencySign="accounting"
                  />
                </div>
              </Grid>
              {/* Promo */}
              <Grid item xs={2} md={1}>
                {props_product.promoPrice > 0 && (
                  <div>
                    <div style={{ fontSize: "14px" }}>Promozione</div>
                    <div
                      style={{
                        backgroundColor: "#ff0000",
                        color: "#ffffff",
                        textAlign: "center",
                        fontSize: "23px",
                      }}
                    >
                      -
                      {Math.round(
                        100 -
                          (100 * props_product.promoPrice) / props_product.price
                      )}
                      %
                    </div>
                  </div>
                )}{" "}
              </Grid>
              {/* cart */}
              <Grid
                item
                xs={2}
                md={4}
                style={{
                  textAlign: "right",
                  paddingTop: theme.spo.mSm,
                  paddingBottom: theme.spo.mSm,
                }}
              >
                {count <= 0 && props_readOnly == false && (
                  <Box>
                    <Box
                      style={{
                        paddingRight: theme.spo.mSm,
                        fontSize: "12px",
                        height: "14px",
                      }}
                    >
                      {props_product.unmis &&
                        props_product.unmis.toLowerCase() == "kg" &&
                        props_product.unmis}
                    </Box>
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={() => {
                        if (!props_noCounter) {
                          setCount(props_product.qtaBase);
                        }
                        props_newProduct(props_product);
                      }}
                    >
                      <FontAwesomeIcon icon={faCartPlus} />
                    </IconButton>
                  </Box>
                )}
                {count > 0 && (
                  <div>
                    <ProductCounter
                      readOnly={props_readOnly}
                      product={props_product}
                      setCount={setCount}
                      count={count}
                      removeProduct={props_removeProduct}
                      updateQuantity={props_updateQuantity}
                    ></ProductCounter>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(GridProduct);
